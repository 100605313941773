import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Car } from './car';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Properties } from 'src/environments/properties';
@Injectable({
  providedIn: 'root'
})
export class APIService {
  apiURL = "https://b1r2wb92ae.execute-api.ca-central-1.amazonaws.com/PROD/vehicles";
  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      "Accept": "*/*",
      "Content-Type": "application/json",
      "X-Api-Key": "5U2qzLv20A3rG55wJDpw58LVp4qswLsAZE2Tfc62"
    })
  }
  getAllCars(): Observable<Car> {
    return this.http.get<Car>(this.apiURL, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getCarByVin(vinNumber): Observable<Car> {
    return this.http.get<Car>(this.apiURL + "?VehicleVin=" + vinNumber, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  createCar(car): Observable<Car> {
    return this.http.post<Car>(this.apiURL, JSON.stringify(car), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  deleteCar(vinNumber): Observable<Car> {
    return this.http.delete<Car>(this.apiURL + "?VehicleVin=" + vinNumber, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getVinDetails(vin, callback) {

    var data = null;

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === this.DONE) {
        try {
          // return this.responseText;
          callback(this.responseText);
        } catch (err) {
          callback("error");
        }
      }
    });

    xhr.open("GET", "https://vindecoder.p.rapidapi.com/v2.0/decode_vin?vin=" + vin);
    xhr.setRequestHeader("x-rapidapi-host", Properties["VinDetail-rapidapi-host"]);
    xhr.setRequestHeader("x-rapidapi-key", Properties["VinDetail-rapidapi-key"]);

    xhr.send(data);



  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }

}
