export const Properties = {
    "Address": "9331 Markham Rd，Markham ON L3P 3J3",
    "Sold-Prefix-URL": "https://img.imageboss.me/kingstarautos3/width/1200/wmk-position:northeast,wmk-path:Sold.png",
    "Phone-Number": "(647) 740-1482",
    "Phone-Number-Alt": "(647) 668-3000",
    "Phone-Calling": "6477401482",
    "Phone-Calling-Alt": "6476683000",
    "Name": "KINGSTAR Auto",
    "Host-Url": "https://vehicles-images-kingstartauto.s3.ca-central-1.amazonaws.com",
    "Host-Name": "https://t084z1npj4.execute-api.eu-central-1.amazonaws.com/production/details/",
    "CarFax-Image": "https://vehicles-images-kingstartauto.s3.ca-central-1.amazonaws.com/carfax.jpg",
    "ImageBoss-Url": "https://img.imageboss.me/kingstarautos3",
    "ImageBoss-1200x400": "/cover:center/1200x400/",
    "ImageBoss-1400x1400": "/cover:center/1400x1400/",
    "ImageBoss-1400": "/width/1400/",
    "ImageBoss-1100": "/width/1100/",
    "ImageBoss-450x300": "/width/2000/",
    "ImageBoss-Cdn": "/cdn/",
    "AWS-Access-Key": "AKIATPWC7LNTW6AGDF6P",
    "AWS-Secret-Key": "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",
    "AWS-Region": "ca-central-1",
    "RapidApI-Url": "https://rapidapi.xxx.xxx",
    "Download-File": "https://vehicles-images-kingstartauto.s3.ca-central-1.amazonaws.com/kingstar-loan-application.pdf",
    "Car-Description-text": `**Our business in the used car industry has a very good reputation for before and after sales service for many years. We carry a large inventory of quality vehicles which includes sedan, SUV, Mini Van and other popular models. Our technicians are professional and have UCDA licenses in Ontario. They conduct strict safety inspections of each vehicle according to government requirements. We provide complete Carfax Reports, Car Proof information and after-sales service. 
    **We provide full leasing and finance.... services with low interest rates, fast approval and simple procedures. All information is transparent, no hidden costs. 
    **Our services are tailored to make your experience of buying, selling and leasing your vehicle simple, easy and hassle free.	
    
    If you need to see a particular car or test drive, please call 647-740-1482 to make an appointment.
    kingstarautosales.ca
    kingstarautosale@gmail.com
     `,
    "DealerID": "00001",
    "VinDetail-rapidapi-host": "vindecoder.p.rapidapi.com",
    "VinDetail-rapidapi-key": "070eea3132msh0415cbbccf78d93p1bf8c0jsn4f7e334cbbe4",
    "Services": [
        {
            "id": "car-wash-and-detailing",
            "name": "Car Wash & Detailing",
            "images": "https://img.imageboss.me/kingstarautos3/width/1400/00001-services/service-carwash.jpg",
            "images1": "https://img.imageboss.me/kingstarautos3/width/1400/ServicesRelatedPictures/CarDetailing1.JPG",
            "images2": "https://img.imageboss.me/kingstarautos3/width/1400/ServicesRelatedPictures/CarDetailing2.JPG",
            "images3": "https://img.imageboss.me/kingstarautos3/width/1400/ServicesRelatedPictures/CarDetailing3.JPG",
            "description": "Kingstar Auto Sales provides you with one-stop car-related services, which includes service from Sparkling Auto Spa & Detailing provides professional car outlook improvement, steam car washing, disinfection, salt removing, polishing, waxing, nanometer crystal plating, 3M car wrapping, car glass foil etc. This professional service makes your vehicle look nice and you will be satisfied with it."
        },
        {
            "id": "car-accessories",
            "name": "Car Accessories",
            "images": "https://img.imageboss.me/kingstarautos3/width/1400/00001-services/service-ccessories.jpg",
            "images1": "https://img.imageboss.me/kingstarautos3/width/1400/ServicesRelatedPictures/CarAccessories1.PNG",
            "images2": "https://img.imageboss.me/kingstarautos3/width/1400/ServicesRelatedPictures/CarAccessories2.JPG",
            "images3": "https://img.imageboss.me/kingstarautos3/width/1400/ServicesRelatedPictures/CarAccessories3.PNG",                
            "description": "Tuxmat is a business that offers 3D water proof car mats. Tuxmat has co-operated with us very closely. You can purchase these mats from us and we will fit them into your car. We have more than 700 various sizes of car mats for almost all models of vehicles in the market. We can find the right professional fit for your vehicle from our stock. We will also provide a 1 year warranty for the mats."
        },
        {
            "id": "mechinal",
            "name": "Mechanical",
            "images": "https://img.imageboss.me/kingstarautos3/width/1400/00001-services/service-mechanical.jpg",
            "images1": "https://img.imageboss.me/kingstarautos3/width/1400/ServicesRelatedPictures/Mechanical1.JPG",
            "images2": "https://img.imageboss.me/kingstarautos3/width/1400/ServicesRelatedPictures/Mechanical2.JPG",
            "description": "Our technical team members are professional and have UCDA licenses in Ontario. They can accurately diagnose the problem of the vehicle and repair it in a short time to make your vehicle safe and reliable.",
            "description2": "We are the Auto Body Shop with government license. No matter how much damage on your car, we can get your car back to its original appearance in the shortest time."
        },
        {
            "id": "vehicle-insurance",
            "name": "Vehicle Insurance & Traffic Tickets",
            "images": "https://img.imageboss.me/kingstarautos3/width/1400/ServicesRelatedPictures/VehicleInsurance1.png",
            "images1": "https://img.imageboss.me/kingstarautos3/width/1400/ServicesRelatedPictures/VehicleInsurance2.png",
            "images3": "https://img.imageboss.me/kingstarautos3/width/1400/ServicesRelatedPictures/VehicleInsurance3.jpg",
            "images2": "https://img.imageboss.me/kingstarautos3/width/1400/ServicesRelatedPictures/VehicleInsurance4.jpg",
            "description": "We co-operate with some insurance companies and dealers. It is our honor to customize your insurance. Help you to find the insurance company with the lowest premium, the best protection and first-class service for you.",
            "description2": "We take care of you with professional agent and we have many years of experience on traffic tickets. We can solve the problems of traffic tickets and accident claims. We provide you the most efficient service and greatest gain possible, recovering your losses and mistakes where possible."
        },
        {
            "id": "24-hrs-towing-service",
            "name": "24 Hrs Towing Service",
            "images": "https://img.imageboss.me/kingstarautos3/width/1400/00001-services/service-towing.jpg",
            "images1": "https://img.imageboss.me/kingstarautos3/width/1400/ServicesRelatedPictures/Towing1.JPG",
            "description": "When you need help, our trailer will be at your side as soon as possible to resolve your problems, such as accidents, report crimes, rescue machinery and other issues."
        },
        {
            "id": "accident-report",
            "name": "Accident Report",
            "images": "https://img.imageboss.me/kingstarautos3/width/1400/00001-services/service-accident.jpg",
            "images1": "https://img.imageboss.me/kingstarautos3/width/1400/ServicesRelatedPictures/AccidentReport1.JPG",
            "images2": "https://img.imageboss.me/kingstarautos3/width/1400/ServicesRelatedPictures/AccidentReport2.JPG",
            "description": "Our professional team of lawyers will fight to protect your interests and compensation to the greatest extent possible. The procedure will be simple and the compensation will be better. Physiotherapy clinic can be introduced to you to cure your pain effectively."
        }
    ],
    "Brands": [
        { "name": "Acura" },
        { "name": "Alfa Romeo" },
        { "name": "Audi" },
        { "name": "BMW" },
        { "name": "Bentley" },
        { "name": "Buick" },
        { "name": "Cadillac" },
        { "name": "Chevrolet" },
        { "name": "Chrysler" },
        { "name": "Dodge" },
        { "name": "Fiat" },
        { "name": "Ford" },
        { "name": "GMC" },
        { "name": "Genesis" },
        { "name": "Honda" },
        { "name": "Hyundai" },
        { "name": "Infiniti" },
        { "name": "Jaguar" },
        { "name": "Jeep" },
        { "name": "Kia" },
        { "name": "Land Rover" },
        { "name": "Lexus" },
        { "name": "Lincoln" },
        { "name": "Lotus" },
        { "name": "Maserati" },
        { "name": "Mazda" },
        { "name": "Mercedes-Benz" },
        { "name": "Mercury" },
        { "name": "Mini" },
        { "name": "Mitsubishi" },
        { "name": "Nissan" },
        { "name": "Pontiac" },
        { "name": "Porsche" },
        { "name": "Ram" },
        { "name": "Rolls-Royce" },
        { "name": "Saab" },
        { "name": "Saturn" },
        { "name": "Scion" },
        { "name": "Smart" },
        { "name": "Subaru" },
        { "name": "Suzuki" },
        { "name": "Tesla" },
        { "name": "Toyota" },
        { "name": "Volkswagen" },
        { "name": "Volvo" }

    ]
};

