import { Component, OnInit } from '@angular/core';
import { Properties } from ',,/../../src/environments/properties';
import { APIService } from '../../services/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isMobile = false;
  logoURL: string;
  services: any = [];
  Cars: any = [];
  brands: { "name": string; }[];
  constructor(public APIService: APIService) { }

  ngOnInit() {
    this.loadScript();
    this.services = Properties.Services;
    this.brands = Properties.Brands;
    this.logoURL = Properties["Host-Url"] + "/" + "Logo.png";
    this.isMobile = this.getIsMobile();
    window.onresize = () => {
      this.isMobile = this.getIsMobile();
    };
    this.loadCars();
  }
  closeNav() {
    let element = document.getElementById('aside');
    element.classList.remove("navigation");
  }
  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 992;
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }
  loadCars() {
    return this.APIService.getAllCars().subscribe((data: {}) => {
      this.Cars = data['body'];
    });
  }

  getBrandCount(brand, status) {
    let car;
    let count = 0;
    for (car in this.Cars) {
      if (this.Cars[car].brand) {
        if (this.Cars[car].brand.toLowerCase().localeCompare(brand.toLowerCase()) == 0 && this.Cars[car].status === status) {
          count++;
        }
      }
    }
    return (count);
  }

  getStatusCount(status) {
    let car;
    let count = 0;
    for (car in this.Cars) {
      if (this.Cars[car].brand) {
        if (this.Cars[car].status === status) {
          count++;
        }
      }
    }
    return (count);
  }
  navigationToggle() {
    let element = document.getElementById('aside');
    if (element.classList.contains('navigation')) {
      element.classList.remove("navigation");
    } else {
      element.classList.add("navigation");
    }
  }
  loadScript() {
    // let body = <HTMLDivElement>document.body;
    // let script = document.createElement('script');
    // script.innerHTML = `

    // var specifiedElement = document.getElementById('aside');

    // document.addEventListener('click', function (event) {
    //   var isClickInside = specifiedElement.contains(event.target);

    //   if (!isClickInside) {
    //     console.log('do something!');
    //   }
    // });
    // `;
    // script.src = 'url';
    // script.async = true;
    // script.defer = true;
    // body.appendChild(script);

    // document.body.addEventListener("click", function (evt) {
    //   if(){
    // document.addEventListener('click', function (event) {

    // If the click happened inside the modal, do nothing
    // if (event.target.closest('#aside', '.sidemenu-btn')) return;

    // Otherwise, close any open modal windows
    // You would add the code for that here...

    // }, false);
    // });
    //   }
    //   let element = document.getElementById('aside');
    //   element.classList.remove("navigation");
    //   evt.stopPropagation();
  }
}
