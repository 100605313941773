import { Component, OnInit } from '@angular/core';
import { APIService } from '../../services/api.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Properties } from ',,/../../src/environments/properties';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  Cars: any = [];
  new: any = [];
  usedHalf: any = [];
  usedSecondHalf: any = [];
  lease: any = [];
  sold: any = [];
  services: any = [];
  banner: string;
  newLength: number;
  usedLength: number;
  leaseLength: number;

  constructor(public APIService: APIService) {
  }

  ngOnInit() {
    this.loadCars();
    this.services = Properties.Services;
    this.banner = Properties["ImageBoss-Url"] + Properties["ImageBoss-1200x400"] + "SuperCar-img2-Cut.png"
  }

  loadCars() {
    return this.APIService.getAllCars().subscribe((data: {}) => {
      this.Cars = data['body'];
      this.sorter();
      // console.log(this.Cars);
      // console.log(this.Cars['body']);
    });

  }

  // sort cars
  sorter() {
    let car;
    let tmpNew = [], tmpUsed = [], tmpLease = [], tmpSold = [];

    for (car in this.Cars) {
      // getting all new cars
      if (this.Cars[car].status == "new") {
        tmpNew.push(this.Cars[car]);
      }

      // getting all used cars
      if (this.Cars[car].status == "used") {
        tmpUsed.push(this.Cars[car]);
      }

      // getting all lease cars
      if (this.Cars[car].status == "lease") {
        tmpLease.push(this.Cars[car]);
      }

      // getting all sold cars
      if (this.Cars[car].status == "sold") {
        tmpSold.push(this.Cars[car]);
      }
    }
    this.newLength = tmpNew.length;
    this.usedLength = tmpUsed.length;
    this.leaseLength = tmpLease.length;
    // this.sold = this.shuffle(tmpSold);
    this.new = this.shuffle(tmpNew).concat(this.shuffle(tmpSold));

    let tmpVal = Math.round(this.usedLength) / 2;
    // console.log(tmpVal);
    // console.log(this.usedLength);
    // console.log(this.usedLength / 2);
    var half_length = Math.ceil(tmpVal);
    // console.log(half_length);
    var leftSide = tmpUsed.slice(0, half_length);
    var rightSide = tmpUsed.slice(half_length, tmpUsed.length);

    this.usedHalf = this.shuffle(leftSide)
    // .concat(this.shuffle(tmpSold));
    this.usedSecondHalf = this.shuffle(rightSide)
    // .concat(this.shuffle(tmpSold));
    // console.log(tmpUsed);
    // console.log(this.usedHalf);
    // console.log(this.usedSecondHalf);
    this.lease = this.shuffle(tmpLease).concat(this.shuffle(tmpSold));
    // console.log(this.new, this.usedHalf, this.usedSecondHalf, this.lease);
  }

  shuffle(sourceArray) {
    for (var i = 0; i < sourceArray.length - 1; i++) {
      var j = i + Math.floor(Math.random() * (sourceArray.length - i));
      var temp = sourceArray[j];
      sourceArray[j] = sourceArray[i];
      sourceArray[i] = temp;
    }
    return sourceArray;
  }

  customOptions: OwlOptions = {
    items: 1,
    autoHeight: true,
    // autoWidth: true,
    loop: true,
    // rewind: true,
    margin: 30,
    nav: true,
    dots: false,
    lazyLoad: true,
    smartSpeed: 900,
    // autoplay: true,
    // @ts-ignore
    navText: ["<i class='fa' style='margin:0px !important;'></i>", "<i class='fa'></i>"],
    responsive: {
      0: {
        items: 1
      },
      480: {
        items: 2
      },
      768: {
        items: 3
      },
      992: {
        items: 3
      }
    }
  }
}
