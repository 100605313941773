import { Component, OnInit } from '@angular/core';
import { Properties } from ',,/../../src/environments/properties';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  address: string;
  year: Number;
  name: string;
  number: string;
  numberalt: string;
  services: any = [];
  callNumber: string;
  callNumberAlt: string;
  constructor() { }

  ngOnInit() {
    this.address = Properties.Address;
    this.year = new Date().getFullYear();
    this.name = Properties.Name;
    this.number = Properties["Phone-Number"];
    this.numberalt = Properties["Phone-Number-Alt"]
    this.callNumber = Properties["Phone-Calling"];
    this.callNumberAlt = Properties["Phone-Calling-Alt"]
    this.services = Properties.Services;
  }
  addressSet() {
    return Properties["ImageBoss-Url"] + Properties["ImageBoss-1400"] + "address1.png";
  }
}
