import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { APIService } from '../../services/api.service';
import { UploadAWSService } from '../../services/upload-aws.service';
import { Properties } from 'src/environments/properties';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxImageCompressService } from 'ngx-image-compress';



@Component({
  selector: 'app-modify',
  templateUrl: './modify.component.html',
  styleUrls: ['./modify.component.scss']
})
export class ModifyComponent implements OnInit {

  @Input() car = {
    VehicleVin: '',
    year: '',
    images: '',
    model: '',
    status: '',
    brand: '',
    dateIn: '',
    dateOut: '',
    milage: '',
    priceIn: '',
    luxuryTrim: '',
    convertable: '',
    featureInfo: '',
    priceOut: '',
    priceSale: '',
    color: '',
    specInfo: '',
    leaseInfo: '',
    sku: '',
    showVin: '',
    carFax: ''
  };
  selectedFiles: any;
  urls = [];
  loginForm: FormGroup;
  vinForm: FormGroup;
  soldPriceForm: FormGroup;
  newCarTypeForm: FormGroup;
  carPriceForm: FormGroup;
  carLeaseForm: FormGroup;
  updatecarForm: FormGroup;
  carUpdateLeaseForm: FormGroup;
  carImagesNew: FormGroup;
  logoURL: string;
  isMobile = false;
  successfulLogin = false;
  submittedVin: boolean;
  submittedSoldPrice: boolean;
  submitted: boolean;
  wrongPassword = false;
  update = false;
  new = false;
  soldUpdate = false;
  product: any;
  wrongVin = false;
  firstOption: boolean;
  specification: any;
  features: any;
  featuresLength: any;
  images: any = [];
  initial_specification: any = [];
  mainImage: any;
  existingVin: boolean;
  carTypePick: boolean;
  newUsedCarForm: boolean;
  submittedLeaseForm: boolean;
  newLeaseCarForm: boolean;
  latesturls: any[];
  vinNumber: string;
  carTypeNewStatus: string;
  sellingPrice: string;
  salePrice: string;
  submittedCarPrice: boolean;
  milage: any;
  color: any;
  description: any;
  specInfo: any;
  leaseDuration: any;
  leaseRemain: any;
  leaseCompany: any;
  leaseFromDate: any;
  leaseTotalMilage: any;
  leaseRemainMilage: any;
  leaseBuyBackAmount: any;
  leaseVehicleTreInsurance: any;
  leaseReturnInsurance: any;
  leaseInitialTransferAmount: any;
  alreadySold: boolean;
  finalSubmit: boolean;
  productDescription: any;
  updateNewUsedValues: boolean;
  updateLeaseValues: boolean;
  updateImages: boolean;
  submittedLeaseCarPrice: boolean;
  preventSubmitNewUsed: boolean;
  preventSubmitLease: boolean;
  fieldMissing: boolean;
  year: any;
  model: any;
  brand: any;
  showingLoad: boolean;
  vinGotten: boolean;
  AllCars: any;
  usedPicker: boolean;
  sku: any;
  carFax: any;
  AllFilteredCars: any;
  filterInputVal: string;
  latestFileList: File[];
  updateError: boolean;
  newMainImage: any;
  showVin: string;
  modelName: any;
  carOptions: boolean;
  carTypeOptions: any;
  masterSelected: boolean;
  finalFeatures: any[];
  carOptionsUpdate: boolean;
  filterCars: any[];
  // localUrl: any;
  localCompressedURl: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;
  compressedImage: any[];
  ggg: any;
  homeURL: string;
  constructor(
    public APIService: APIService,
    public router: Router,
    private uploadService: UploadAWSService,
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef,
    private httpService: HttpClient,
    private imageCompress: NgxImageCompressService
  ) {
  }

  ngOnInit() {
    this.getCaroptions();

    this.logoURL = Properties["Host-Url"] + "/Logo.png";
    this.homeURL = Properties["Host-Name"] + "/home";
    this.isMobile = this.getIsMobile();
    window.onresize = () => {
      this.isMobile = this.getIsMobile();
    };
    this.loginForm = this.formBuilder.group({
      password: ['', [Validators.required]]
    });
    this.vinForm = this.formBuilder.group({
      vinNumber: ['', [Validators.required]]
    });
    this.soldPriceForm = this.formBuilder.group({
      soldPrice: ['', [Validators.required]]
    });

    this.newCarTypeForm = this.formBuilder.group({
      carType: ['', Validators.required]
    });
    // this.APIService = this.formBuilder.group({
    //   mainImageSet: ['', Validators.required]
    // });
    this.carPriceForm = this.formBuilder.group({
      showVin: ['', Validators.required],
      sku: [''],
      sellingPrice: ['', Validators.required],
      salePrice: [''],
      Milage: ['', Validators.required],
      Color: ['', Validators.required],
      modelName: ['', Validators.required],
      Description: ['', Validators.required],
      CarFax: ['']
    });

    this.carLeaseForm = this.formBuilder.group({
      showVin: [''],
      sku: [''],
      leaseDuration: ['', Validators.required],
      leaseRemain: ['', Validators.required],
      leaseCompany: ['', Validators.required],
      leaseFromDate: ['', Validators.required],
      leaseTotalMilage: ['', Validators.required],
      leaseRemainMilage: ['', Validators.required],
      leaseBuyBackAmount: ['', Validators.required],
      leaseInitialTransferAmount: ['', Validators.required],
      leaseReturnInsurance: ['', Validators.required],
      leaseVehicleTreInsurance: ['', Validators.required],
      Milage: ['', Validators.required],
      Color: ['', Validators.required],
      modelName: ['', Validators.required],
      Description: ['', Validators.required],
      sellingPrice: ['', Validators.required],
      salePrice: [''],
      CarFax: ['']
    });

    this.updatecarForm = this.formBuilder.group({
      showVin: [''],
      sku: [''],
      sellingPrice: ['', Validators.required],
      salePrice: [''],
      Milage: ['', Validators.required],
      Color: ['', Validators.required],
      modelName: ['', Validators.required],
      Description: ['', Validators.required],
      CarFax: ['']
    });

    this.carUpdateLeaseForm = this.formBuilder.group({
      showVin: [''],
      sku: [''],
      leaseDuration: ['', Validators.required],
      leaseRemain: ['', Validators.required],
      leaseCompany: ['', Validators.required],
      leaseFromDate: ['', Validators.required],
      leaseTotalMilage: ['', Validators.required],
      leaseRemainMilage: ['', Validators.required],
      leaseBuyBackAmount: ['', Validators.required],
      leaseInitialTransferAmount: ['', Validators.required],
      leaseReturnInsurance: ['', Validators.required],
      leaseVehicleTreInsurance: ['', Validators.required],
      Milage: ['', Validators.required],
      Color: ['', Validators.required],
      modelName: ['', Validators.required],
      Description: ['', Validators.required],
      sellingPrice: ['', Validators.required],
      salePrice: [''],
      CarFax: ['']
    });
    this.carImagesNew = this.formBuilder.group({
      carImages: ['']
    });
  }

  getCaroptions() {
    this.httpService.get('https://vehicles-images-kingstartauto.s3.ca-central-1.amazonaws.com/Vehicle-Feature-List.json').subscribe(
      data => {
        // this.carTypeOptions = data as string[];
        this.carTypeOptions = data;
        let count = 1;
        for (let CarOption in this.carTypeOptions.Result) {
          for (let carOpt in this.carTypeOptions.Result[CarOption]) {
            for (let [finalKey, finalValue] of Object.entries(this.carTypeOptions.Result[CarOption][carOpt])) {
              this.carTypeOptions.Result[CarOption][carOpt][finalKey] = [{ id: count, name: finalKey, value: finalKey + "|" + carOpt, isSelected: false }];
              count++;
            }

          }

        }
      },
      (err: HttpErrorResponse) => {
        // console.log(err.message);
      }
    );
  }

  checkUncheckAll() {
    for (let CarOption in this.carTypeOptions.Result) {
      for (let carOpt in this.carTypeOptions.Result[CarOption]) {

        for (let [finalKey, finalValue] of Object.entries(this.carTypeOptions.Result[CarOption][carOpt])) {
          for (let fin in this.carTypeOptions.Result[CarOption][carOpt][finalKey]) {
            this.carTypeOptions.Result[CarOption][carOpt][finalKey][fin].isSelected = this.masterSelected;
            // for (let [newFinalKey, newFinalValue] of Object.entries(this.carTypeOptions.Result[CarOption][carOpt][finalKey][fin])) {
            // }
          }
          // this.carTypeOptions.Result[CarOption][carOpt][finalKey] = [{ id: count, value: finalKey + " " + carOpt, isSelected: false }];
          // count++;
        }

      }

    }
  }
  isAllSelected() {
    this.masterSelected = this.carTypeOptions.Result.every(function (item: any) {
      for (let carOpt in item) {

        for (let [finalKey, finalValue] of Object.entries(item[carOpt])) {
          for (let fin in item[carOpt][finalKey]) {
            return item[carOpt][finalKey][fin].isSelected == true;
          }

        }

      }

    })
  }



  closeNav() {
    let element = document.getElementById('aside');
    element.classList.remove("navigation");
    this.tabOptions();
  }

  navigationToggle() {
    let element = document.getElementById('aside');
    if (element.classList.contains('navigation')) {
      element.classList.remove("navigation");
    } else {
      element.classList.add("navigation");
    }
  }

  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 992;
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }

  get loginValues() {
    return this.loginForm.controls;
  }
  get vinNumberValues() {
    return this.vinForm.controls;
  }

  get soldPriceValues() {
    return this.soldPriceForm.controls;
  }

  get sellingPriceValues() {
    return this.carPriceForm.controls;
  }

  get updateCarValues() {
    return this.updatecarForm.controls;
  }

  get LeaseFormValues() {
    return this.carLeaseForm.controls;
  }

  get LeaseFormUpdateValues() {
    return this.carUpdateLeaseForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.finalSubmit = false;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.wrongPassword = true;
      return;
    }

    var password = this.loginForm.controls['password'].value;

    // console.log(password);
    if (password === "1111") {
      // console.log("here");
      this.successfulLogin = true;
      this.firstOption = true;
      // this.new = true;
    } else {
      this.wrongPassword = true;
    }
  }

  onSubmitVin() {
    this.submittedVin = true;
    // stop here if form is invalid
    if (this.vinForm.invalid) {
      return;
    }
  }

  onSubmitUpdateCar() {
    this.submittedCarPrice = true;
    // stop here if form is invalid
    if (this.updatecarForm.invalid) {
      return;
    }
    if (this.preventSubmitNewUsed) {
      this.updateImages = true;
      this.updateNewUsedValues = false;
    }

  }
  onSubmitSoldPrice() {
    this.submittedSoldPrice = true;
    let soldPrice = this.soldPriceForm.controls['soldPrice'].value;
    // console.log(soldPrice);
    if (soldPrice > 0) {
      this.product.status = 'sold';
      this.product.dateOut = this.currentDate();
      this.product.priceOut = soldPrice;
      this.APIService.createCar(this.product).subscribe((data: {}) => {
        // this.router.navigate(['/admin/']);
        this.tabOptions();
        this.finalSubmit = true;
      });
    }
  }

  onSubmitCarLeaseUpdateForm() {
    this.submittedLeaseCarPrice = true;
    // console.log(this.preventSubmitLease);
    // stop here if form is invalid
    if (this.carUpdateLeaseForm.invalid) {
      return;
    }
    if (this.preventSubmitLease) {
      this.updateImages = true;
      this.updateLeaseValues = false;
    }
  }
  async updateCarOption(val) {
    this.showingLoad = true;
    this.updateImages = false;
    // console.log(this.product);
    let tmpStatus = this.product.status.split(" ");
    if (val === 'show') {
      if (tmpStatus[0] === 'lease') {
        // console.log(this.product);
        if (this.product.VehicleVin == null) {
          this.fieldMissing = true;
          this.updateImages = false;
          // return;
        }
        let carImageObj = { "images": [] };

        // console.log(result);
        // console.log(this.product);
        // let carImageObj = { "images": [] };
        if (this.images.length >= 1) {
          // carImageObj = { "images": [...this.images] };
          for (let image in this.images) {
            var existingImage = this.images[image].split("/");
            // console.log("name", existingImage[7]);
            if (this.images[image] === this.newMainImage) {
              carImageObj.images.unshift({ "name": existingImage[7] });
            } else {
              carImageObj.images.push({ "name": existingImage[7] });
            }
          }
        }

        let result;
        result = await this.upload();

        for (let i = 0; i < result.length; i++) {
          // console.log(result[i]);
          var actualImage = result[i].split("/");
          if (result[i] === this.newMainImage) {
            carImageObj.images.unshift({ "name": actualImage[4] });
          } else {
            carImageObj.images.push({ "name": actualImage[4] });
          }

        }

        // console.log(this.product);
        var description = { "description": this.carUpdateLeaseForm.controls['Description'].value };

        this.product.priceIn = this.carUpdateLeaseForm.controls['sellingPrice'].value;
        this.salePrice = this.carUpdateLeaseForm.controls['salePrice'].value;

        if (parseInt(this.salePrice) > 0) {
          this.product.priceSale = this.salePrice;
        } else {
          this.product.priceSale = null;
        }

        let tmpYear = this.year;
        let tmpModel = this.carUpdateLeaseForm.controls['modelName'].value;
        let tmpBrand = this.brand;

        let val = JSON.parse(this.specInfo);
        if ("success" in val && val["success"] == true) {
          tmpYear = val["specification"]["year"];
          val["specification"]["model"] = tmpModel;
          tmpBrand = val["specification"]["make"];
          val["specification"]["features"] = this.finalFeatures;
        }


        // console.log(val);

        this.product.specInfo = JSON.stringify(val);
        this.product.brand = tmpBrand;
        this.product.model = tmpModel;
        this.product.year = tmpYear;
        this.product.showVin = this.carUpdateLeaseForm.controls['showVin'].value;
        if (this.carUpdateLeaseForm.controls['sku'].value && this.carUpdateLeaseForm.controls['sku'].value !== null) {
          this.product.sku = this.carUpdateLeaseForm.controls['sku'].value;
        } else {
          this.product.sku = null;
        }
        if (this.carUpdateLeaseForm.controls['CarFax'].value && this.carUpdateLeaseForm.controls['CarFax'].value !== null) {
          this.product.carFax = this.carUpdateLeaseForm.controls['CarFax'].value;
        } else {
          this.product.carFax = null;
        }

        this.product.milage = this.carUpdateLeaseForm.controls['Milage'].value;
        this.product.color = this.carUpdateLeaseForm.controls['Color'].value;
        this.product.featureInfo = JSON.stringify(description);
        this.product.status = tmpStatus[0];
        this.product.images = JSON.stringify(carImageObj);
        // console.log(this.carUpdateLeaseForm.controls['leaseDuration'].value, this.carUpdateLeaseForm.controls['leaseRemain'].value, this.carUpdateLeaseForm.controls['leaseCompany'].value
        //   , this.carUpdateLeaseForm.controls['leaseFromDate'].value,
        //   this.carUpdateLeaseForm.controls['leaseTotalMilage'].value,
        //   this.carUpdateLeaseForm.controls['leaseRemainMilage'].value,
        //   this.carUpdateLeaseForm.controls['leaseBuyBackAmount'].value,
        //   this.carUpdateLeaseForm.controls['leaseInitialTransferAmount'].value,
        //   this.carUpdateLeaseForm.controls['leaseReturnInsurance'].value,
        //   this.carUpdateLeaseForm.controls['leaseVehicleTreInsurance'].value
        // );
        let tmpLease = {
          'Lease Duration': this.carUpdateLeaseForm.controls['leaseDuration'].value,
          'Lease Remain': this.carUpdateLeaseForm.controls['leaseRemain'].value,
          'Lease Company': this.carUpdateLeaseForm.controls['leaseCompany'].value,
          'Lease From Date': this.carUpdateLeaseForm.controls['leaseFromDate'].value,
          'Lease Total Milage': this.carUpdateLeaseForm.controls['leaseTotalMilage'].value,
          'Lease Remain Milage': this.carUpdateLeaseForm.controls['leaseRemainMilage'].value,
          'Lease Buy Back Amount': this.carUpdateLeaseForm.controls['leaseBuyBackAmount'].value,
          'Lease Initial Transfer Amount': this.carUpdateLeaseForm.controls['leaseInitialTransferAmount'].value,
          'Lease Return Insurance': this.carUpdateLeaseForm.controls['leaseReturnInsurance'].value,
          'Lease Vehicle Tre Insurance': this.carUpdateLeaseForm.controls['leaseVehicleTreInsurance'].value
        };

        this.product.leaseInfo = JSON.stringify(tmpLease);

        // console.log(this.product);
        if (this.product.VehicleVin == null) {
          this.fieldMissing = true;
          this.updateImages = false;
          // return;
        }
        this.APIService.createCar(this.product).subscribe((data: {}) => {
          // this.router.navigate(['/admin/']);
          this.tabOptions();
          this.finalSubmit = true;
        });
        // });
      } else {
        // console.log("1");
        if (this.product.VehicleVin == null) {
          this.fieldMissing = true;
          this.updateImages = false;
          // return;
        }
        // let carImageObj = { "images": [] };

        // console.log("2");
        // console.log(result);
        let carImageObj = { "images": [] };
        if (this.images.length >= 1) {
          // console.log("3");
          // carImageObj = { "images": [...this.images] };
          for (let image in this.images) {
            var existingImage = this.images[image].split("/");
            // console.log(existingImage);
            // console.log("name", existingImage[9]);
            // console.log("4");
            if (this.images[image] === this.newMainImage) {
              // console.log(this.images[image]);
              carImageObj.images.unshift({ "name": existingImage[7] });
            } else {
              carImageObj.images.push({ "name": existingImage[7] });
            }
            // carImageObj.images.push({ "name": existingImage[9] });
          }
        }
        // console.log("5");
        // console.log("here");
        // console.log(this.images);
        // console.log(carImageObj);
        let result;
        result = await this.upload();
        for (let i = 0; i < result.length; i++) {
          // console.log(result[i]);
          var actualImage = result[i].split("/");
          // console.log("6 check");
          // carImageObj.images.push({ "name": actualImage[4] });
          if (result[i] === this.newMainImage) {
            carImageObj.images.unshift({ "name": actualImage[4] });
          } else {
            carImageObj.images.push({ "name": actualImage[4] });
          }
        }

        // console.log("7");
        // console.log(carImageObj);
        var description = { "description": this.updatecarForm.controls['Description'].value };

        this.product.priceIn = this.updatecarForm.controls['sellingPrice'].value;
        this.salePrice = this.updatecarForm.controls['salePrice'].value;

        if (parseInt(this.salePrice) > 0) {
          this.product.priceSale = this.salePrice;
        } else {
          this.product.priceSale = null;
        }
        let tmpYear = this.year;
        let tmpModel = this.updatecarForm.controls['modelName'].value;
        let tmpBrand = this.brand;

        let val = JSON.parse(this.specInfo);
        if ("success" in val && val["success"] == true) {
          tmpYear = val["specification"]["year"];
          val["specification"]["model"] = tmpModel;
          tmpBrand = val["specification"]["make"];
          val["specification"]["features"] = this.finalFeatures;
        }
        // console.log(val);

        this.product.specInfo = JSON.stringify(val);
        this.product.brand = tmpBrand;
        this.product.model = tmpModel;
        this.product.year = tmpYear;
        this.product.showVin = this.updatecarForm.controls['showVin'].value;
        if (this.updatecarForm.controls['sku'].value && this.updatecarForm.controls['sku'].value !== null) {
          this.product.sku = this.updatecarForm.controls['sku'].value;
        } else {
          this.product.sku = null;
        }
        if (this.updatecarForm.controls['CarFax'].value && this.updatecarForm.controls['CarFax'].value !== null) {
          this.product.carFax = this.updatecarForm.controls['CarFax'].value;
        } else {
          this.product.carFax = null;
        }

        // this.product.sku = this.updatecarForm.controls['sku'].value;
        // this.product.carFax = this.updatecarForm.controls['CarFax'].value;
        this.product.milage = this.updatecarForm.controls['Milage'].value;
        this.product.color = this.updatecarForm.controls['Color'].value;
        this.product.featureInfo = JSON.stringify(description);
        this.product.leaseInfo = null;
        this.product.status = tmpStatus[0];
        // console.log("8");
        this.product.images = JSON.stringify(carImageObj);
        // console.log(this.product);
        if (this.product.VehicleVin == null) {
          this.fieldMissing = true;
          this.updateImages = false;
          // return;
        }
        this.APIService.createCar(this.product).subscribe((data: {}) => {
          // this.router.navigate(['/admin/']);
          this.tabOptions();
          this.finalSubmit = true;
        });

      }
    } else if (val === 'save') {
      if (tmpStatus[0] === 'lease') {
        if (this.product.VehicleVin == null) {
          this.fieldMissing = true;
          this.updateImages = false;
          // return;
        }

        // console.log(result);
        let carImageObj = { "images": [] };
        if (this.images.length >= 1) {

          // carImageObj = { "images": [...this.images] };
          for (let image in this.images) {
            var existingImage = this.images[image].split("/");
            // carImageObj.images.push({ "name": existingImage[9] });
            if (this.images[image] === this.newMainImage) {
              carImageObj.images.unshift({ "name": existingImage[7] });
            } else {
              carImageObj.images.push({ "name": existingImage[7] });
            }
          }
        }
        let result;
        result = await this.upload();
        for (let i = 0; i < result.length; i++) {
          // console.log(result[i]);
          var actualImage = result[i].split("/");
          if (result[i] === this.newMainImage) {
            carImageObj.images.unshift({ "name": actualImage[4] });
          } else {
            carImageObj.images.push({ "name": actualImage[4] });
          }
          // carImageObj.images.push({ "name": actualImage[4] });
        }

        var description = { "description": this.carUpdateLeaseForm.controls['Description'].value };

        this.product.priceIn = this.carUpdateLeaseForm.controls['sellingPrice'].value;
        this.salePrice = this.carUpdateLeaseForm.controls['salePrice'].value;

        if (parseInt(this.salePrice) > 0) {
          this.product.priceSale = this.salePrice;
        } else {
          this.product.priceSale = null;
        }
        let tmpYear = this.year;
        let tmpModel = this.carUpdateLeaseForm.controls['modelName'].value;
        let tmpBrand = this.brand;

        let val = JSON.parse(this.specInfo);
        if ("success" in val && val["success"] == true) {
          tmpYear = val["specification"]["year"];
          val["specification"]["model"] = tmpModel;
          tmpBrand = val["specification"]["make"];
          val["specification"]["features"] = this.finalFeatures;
        }

        // console.log(val);

        this.product.specInfo = JSON.stringify(val);
        this.product.brand = tmpBrand;
        this.product.model = tmpModel;
        this.product.year = tmpYear;
        this.product.images = JSON.stringify(carImageObj);
        this.product.milage = this.carUpdateLeaseForm.controls['Milage'].value;
        this.product.color = this.carUpdateLeaseForm.controls['Color'].value;
        this.product.featureInfo = JSON.stringify(description);
        this.product.showVin = this.carUpdateLeaseForm.controls['showVin'].value;
        if (this.carUpdateLeaseForm.controls['sku'].value && this.carUpdateLeaseForm.controls['sku'].value !== null) {
          this.product.sku = this.carUpdateLeaseForm.controls['sku'].value;
        } else {
          this.product.sku = null;
        }
        if (this.carUpdateLeaseForm.controls['CarFax'].value && this.carUpdateLeaseForm.controls['CarFax'].value !== null) {
          this.product.carFax = this.carUpdateLeaseForm.controls['CarFax'].value;
        } else {
          this.product.carFax = null;
        }

        // this.product.sku = this.carUpdateLeaseForm.controls['sku'].value
        // this.product.carFax = this.carUpdateLeaseForm.controls['CarFax'].value;
        // this.product.status = tmpStatus[0];
        // console.log(this.carUpdateLeaseForm.controls['leaseDuration'].value, this.carUpdateLeaseForm.controls['leaseRemain'].value, this.carUpdateLeaseForm.controls['leaseCompany'].value
        //   , this.carUpdateLeaseForm.controls['leaseFromDate'].value,
        //   this.carUpdateLeaseForm.controls['leaseTotalMilage'].value,
        //   this.carUpdateLeaseForm.controls['leaseRemainMilage'].value,
        //   this.carUpdateLeaseForm.controls['leaseBuyBackAmount'].value,
        //   this.carUpdateLeaseForm.controls['leaseInitialTransferAmount'].value,
        //   this.carUpdateLeaseForm.controls['leaseReturnInsurance'].value,
        //   this.carUpdateLeaseForm.controls['leaseVehicleTreInsurance'].value
        // );
        let tmpLease = {
          'Lease Duration': this.carUpdateLeaseForm.controls['leaseDuration'].value,
          'Lease Remain': this.carUpdateLeaseForm.controls['leaseRemain'].value,
          'Lease Company': this.carUpdateLeaseForm.controls['leaseCompany'].value,
          'Lease From Date': this.carUpdateLeaseForm.controls['leaseFromDate'].value,
          'Lease Total Milage': this.carUpdateLeaseForm.controls['leaseTotalMilage'].value,
          'Lease Remain Milage': this.carUpdateLeaseForm.controls['leaseRemainMilage'].value,
          'Lease Buy Back Amount': this.carUpdateLeaseForm.controls['leaseBuyBackAmount'].value,
          'Lease Initial Transfer Amount': this.carUpdateLeaseForm.controls['leaseInitialTransferAmount'].value,
          'Lease Return Insurance': this.carUpdateLeaseForm.controls['leaseReturnInsurance'].value,
          'Lease Vehicle Tre Insurance': this.carUpdateLeaseForm.controls['leaseVehicleTreInsurance'].value
        };

        this.product.leaseInfo = JSON.stringify(tmpLease);

        // console.log(this.product);
        if (this.product.VehicleVin == null) {
          this.fieldMissing = true;
          this.updateImages = false;
          // return;
        }
        this.APIService.createCar(this.product).subscribe((data: {}) => {
          // this.router.navigate(['/admin/']);
          this.tabOptions();
          this.finalSubmit = true;
        });

      } else {
        if (this.product.VehicleVin == null) {
          this.fieldMissing = true;
          this.updateImages = false;
          // return;
        }

        // console.log(result);
        let carImageObj = { "images": [] };
        if (this.images.length >= 1) {


          // carImageObj = { "images": [...this.images] };

          for (let image in this.images) {
            var existingImage = this.images[image].split("/");
            // carImageObj.images.push({ "name": existingImage[9] });
            if (this.images[image] === this.newMainImage) {
              carImageObj.images.unshift({ "name": existingImage[7] });
            } else {
              carImageObj.images.push({ "name": existingImage[7] });
            }
          }
        }
        let result;
        result = await this.upload();
        for (let i = 0; i < result.length; i++) {
          // console.log(result[i]);
          var actualImage = result[i].split("/");
          if (result[i] === this.newMainImage) {
            carImageObj.images.unshift({ "name": actualImage[4] });
          } else {
            carImageObj.images.push({ "name": actualImage[4] });
          }
          // carImageObj.images.push({ "name": actualImage[4] });
        }

        var description = { "description": this.updatecarForm.controls['Description'].value };

        this.product.priceIn = this.updatecarForm.controls['sellingPrice'].value;
        this.salePrice = this.updatecarForm.controls['salePrice'].value;

        if (parseInt(this.salePrice) > 0) {
          this.product.priceSale = this.salePrice;
        } else {
          this.product.priceSale = null;
        }
        let tmpYear = this.year;
        let tmpModel = this.updatecarForm.controls['modelName'].value;
        let tmpBrand = this.brand;

        let val = JSON.parse(this.specInfo);
        if ("success" in val && val["success"] == true) {
          tmpYear = val["specification"]["year"];
          val["specification"]["model"] = tmpModel;
          tmpBrand = val["specification"]["make"];
          val["specification"]["features"] = this.finalFeatures;
        }
        // console.log(val);

        this.product.specInfo = JSON.stringify(val);
        this.product.brand = tmpBrand;
        this.product.model = tmpModel;
        this.product.year = tmpYear;
        this.product.images = JSON.stringify(carImageObj);
        this.product.showVin = this.updatecarForm.controls['showVin'].value;

        if (this.updatecarForm.controls['sku'].value && this.updatecarForm.controls['sku'].value !== null) {
          this.product.sku = this.updatecarForm.controls['sku'].value;
        } else {
          this.product.sku = null;
        }
        if (this.updatecarForm.controls['CarFax'].value && this.updatecarForm.controls['CarFax'].value !== null) {
          this.product.carFax = this.updatecarForm.controls['CarFax'].value;
        } else {
          this.product.carFax = null;
        }
        this.product.milage = this.updatecarForm.controls['Milage'].value;
        this.product.color = this.updatecarForm.controls['Color'].value;
        this.product.featureInfo = JSON.stringify(description);
        this.product.leaseInfo = null;
        // this.product.status = tmpStatus[0];

        // console.log(this.product);
        if (this.product.VehicleVin == null) {
          this.fieldMissing = true;
          this.updateImages = false;
          // return;
        }
        this.APIService.createCar(this.product).subscribe((data: {}) => {
          // this.router.navigate(['/admin/']);
          this.tabOptions();
          this.finalSubmit = true;
        });


      }
    }
  }

  submitForm(val) {
    this[val] = true;
    // console.log(this.preventSubmitLease);
  }
  onSubmitCarPriceForm() {
    this.submittedCarPrice = true;

    if (this.carPriceForm.invalid) {
      return;
    }
    this.showVin = this.carPriceForm.controls['showVin'].value;
    this.sku = this.carPriceForm.controls['sku'].value;
    this.carFax = this.carPriceForm.controls['CarFax'].value;
    this.sellingPrice = this.carPriceForm.controls['sellingPrice'].value;
    this.salePrice = this.carPriceForm.controls['salePrice'].value > 0 ? this.carPriceForm.controls['salePrice'].value : 0;
    this.milage = this.carPriceForm.controls['Milage'].value;
    this.color = this.carPriceForm.controls['Color'].value;
    this.description = this.carPriceForm.controls['Description'].value;
    this.model = this.carPriceForm.controls['modelName'].value;
    if (parseInt(this.sellingPrice) > 0) {
      this.new = true;
      this.newUsedCarForm = false;
    }

  }

  onSubmitCarLeaseForm() {
    this.submittedLeaseForm = true;

    if (this.carLeaseForm.invalid) {
      return;
    }
    this.showVin = this.carLeaseForm.controls['showVin'].value;
    this.sku = this.carLeaseForm.controls['sku'].value;
    this.carFax = this.carLeaseForm.controls['CarFax'].value;
    this.leaseDuration = this.carLeaseForm.controls['leaseDuration'].value;
    this.leaseRemain = this.carLeaseForm.controls['leaseRemain'].value;
    this.leaseCompany = this.carLeaseForm.controls['leaseCompany'].value;
    this.leaseFromDate = this.carLeaseForm.controls['leaseFromDate'].value;
    this.leaseTotalMilage = this.carLeaseForm.controls['leaseTotalMilage'].value;
    this.leaseRemainMilage = this.carLeaseForm.controls['leaseRemainMilage'].value;
    this.leaseBuyBackAmount = this.carLeaseForm.controls['leaseBuyBackAmount'].value;
    this.leaseInitialTransferAmount = this.carLeaseForm.controls['leaseInitialTransferAmount'].value;
    this.leaseReturnInsurance = this.carLeaseForm.controls['leaseReturnInsurance'].value;
    this.leaseVehicleTreInsurance = this.carLeaseForm.controls['leaseVehicleTreInsurance'].value;
    this.milage = this.carLeaseForm.controls['Milage'].value;
    this.color = this.carLeaseForm.controls['Color'].value;
    this.description = this.carLeaseForm.controls['Description'].value;
    this.model = this.carLeaseForm.controls['modelName'].value;
    this.sellingPrice = this.carLeaseForm.controls['sellingPrice'].value;
    this.salePrice = this.carLeaseForm.controls['salePrice'].value > 0 ? this.carLeaseForm.controls['salePrice'].value : 0;
    this.newLeaseCarForm = false;
    this.new = true;
  }

  async updateOption(value) {
    if (this.vinForm.invalid) {
      return;
    }
    // this.product = {};
    // get vin number
    var vinNumber = this.vinNumber = this.vinForm.controls['vinNumber'].value;

    if (value === 'new') {
      this.APIService.getCarByVin(vinNumber).subscribe((data: {}) => {
        let tmpProduct = data['body']['Item'];
        if (typeof (tmpProduct) === 'undefined') {
          this.APIService.getVinDetails(vinNumber, (result) => {
            // console.log(result);

            if (result !== "error") {
              this.specInfo = result;
              let val = JSON.parse(result);
              if ("success" in val && val["success"] == true) {

                this.carOptions = true;
                this.vinGotten = false;
                this.product = {};

                this.year = val["specification"]["year"];
                this.model = val["specification"]["model"];
                this.brand = val["specification"]["make"];
                this.product.year = val["specification"]["year"];
                this.product.brand = val["specification"]["make"];
                this.product.model = val["specification"]["model"];
                this.initial_specification.year = val["specification"]["year"];
                this.initial_specification.VehicleVin = val["specification"]["vin"];
                this.initial_specification.brand = val["specification"]["make"];
                this.initial_specification.model = val["specification"]["model"];
                this.initial_specification.style = val["specification"]["style"];
                this.initial_specification.transmission = val["specification"]["transmission"];

                // console.log(this.carTypePick, "here");
                // console.log("worked");
              }
            } else {

              this.wrongVin = true;
            }


          });
        } else {
          this.existingVin = true;
        }
      });

      // console.log(val);
    } else {
      // search if vin number is correct
      this.APIService.getCarByVin(vinNumber).subscribe(async (data: {}) => {
        this.product = data['body']['Item'];
        if (typeof (this.product) == 'undefined') {
          this.wrongVin = true;
          this.soldUpdate = false;
          this.updateLeaseValues = false;
          this.updateNewUsedValues = false;
        } else {
          this.wrongVin = false;
          this.getSpecification();
          this.getDescription();
          this.specInfo = this.product.specInfo;

          let tmpSpec = JSON.parse(this.specInfo);
          this.finalFeatures = tmpSpec["specification"]["features"];
          // console.log(this.finalFeatures);
          let tmp = JSON.parse(this.product.images);
          if (tmp.images.length > 0) {
            this.imageConverter(this.product.status, this.product.images, this.product.VehicleVin);
            this.imagesSorter(this.product.status, this.product.VehicleVin);
          }


          // console.log(typeof (this.product));
          // console.log(this.product);
          if (value === 'sold') {
            if (this.product.status === 'sold') {
              this.product = false;
              this.alreadySold = true;
              this.tabOptions();
            } else {
              this.soldUpdate = true;
              this.updateLeaseValues = false;
              this.updateNewUsedValues = false;
              this.vinGotten = false;
            }
          } else if (value === 'update') {


            let tmpStatus = this.product.status.split(" ");

            if (tmpStatus[0] === 'new' || tmpStatus[0] === 'used' || tmpStatus[0] === 'lease') {
              for (let CarOption in this.carTypeOptions.Result) {
                for (let carOpt in this.carTypeOptions.Result[CarOption]) {

                  for (let [finalKey, finalValue] of Object.entries(this.carTypeOptions.Result[CarOption][carOpt])) {
                    for (let fin in this.carTypeOptions.Result[CarOption][carOpt][finalKey]) {
                      if (this.finalFeatures.includes(this.carTypeOptions.Result[CarOption][carOpt][finalKey][fin].value)) {
                        this.carTypeOptions.Result[CarOption][carOpt][finalKey][fin].isSelected = true;
                      }

                      // for (let [newFinalKey, newFinalValue] of Object.entries(this.carTypeOptions.Result[CarOption][carOpt][finalKey][fin])) {
                      //   console.log(newFinalKey);
                      // }
                      // console.log(fin);
                    }
                    // this.carTypeOptions.Result[CarOption][carOpt][finalKey] = [{ id: count, value: finalKey + " " + carOpt, isSelected: false }];
                    // count++;
                  }

                }

              }



              // console.log(this.carTypeOptions);
              this.carOptionsUpdate = true;
              this.updateNewUsedValues = false;
              this.soldUpdate = false;
              this.vinGotten = false;
            } else {
              this.product = false;
              // console.log(this.product);
              this.updateError = true;
              // for (const prop of Object.getOwnPropertyNames(this.product)) {
              //   delete this.product[prop];
              // }

              //   this.soldUpdate = false;
              // this.vinGotten = false;
            }

          } else if (value === 'delete') {
            this.product = false;
            let tmp = Properties["DealerID"] + "-" + vinNumber + '/';
            await this.uploadService.emptyS3Directory(tmp);

            this.APIService.deleteCar(vinNumber).subscribe((data: {}) => {
              this.onReset('vinForm');
              alert("Vehicle of Vin Number: " + vinNumber + " has been deleted.");
              this.tabOptions();
            });

          } else if (value === 'view') {
            let vehicleView = Properties["Host-Name"] + this.vinNumber;
            window.open(vehicleView, "_blank");
          }
        }
        // this.getDescription();
        // this.getSpecification();
        // this.imageConverter(this.product.status, this.product.images, this.product.VehicleVin)
        // this.imagesSorter(this.product.status, this.product.VehicleVin);
      });
    }
    // console.log(this.product);
  }
  optionsPickedUpdate() {
    this.finalFeatures = [];

    for (let CarOption in this.carTypeOptions.Result) {
      for (let carOpt in this.carTypeOptions.Result[CarOption]) {

        for (let [finalKey, finalValue] of Object.entries(this.carTypeOptions.Result[CarOption][carOpt])) {
          for (let fin in this.carTypeOptions.Result[CarOption][carOpt][finalKey]) {
            if (this.carTypeOptions.Result[CarOption][carOpt][finalKey][fin].isSelected) {
              this.finalFeatures.push(this.carTypeOptions.Result[CarOption][carOpt][finalKey][fin].value);
            }
          }

        }

      }

    }
    let tmpStatus = this.product.status.split(" ");
    if (tmpStatus[0] === 'new' || tmpStatus[0] === 'used') {
      this.updateNewUsedValues = true;
      this.carOptionsUpdate = false;

    } else if (tmpStatus[0] === 'lease') {
      this.updateLeaseValues = true;
      this.carOptionsUpdate = false;
    }
  }
  optionsPicked() {
    this.finalFeatures = [];

    for (let CarOption in this.carTypeOptions.Result) {
      for (let carOpt in this.carTypeOptions.Result[CarOption]) {

        for (let [finalKey, finalValue] of Object.entries(this.carTypeOptions.Result[CarOption][carOpt])) {
          for (let fin in this.carTypeOptions.Result[CarOption][carOpt][finalKey]) {
            if (this.carTypeOptions.Result[CarOption][carOpt][finalKey][fin].isSelected) {
              this.finalFeatures.push(this.carTypeOptions.Result[CarOption][carOpt][finalKey][fin].value);
            }
          }

        }

      }

    }
    // console.log(this.finalFeatures);
    this.carTypePick = true;
    this.carOptions = false;
  }

  onSubmitCarTypeForm() {
    // console.log("hrere");
    var carType = this.newCarTypeForm.controls['carType'].value;
    // console.log(carType, "here");
    if (carType === 'new' || carType === 'used') {
      this.newUsedCarForm = true;
      this.carTypePick = false;
      this.newLeaseCarForm = false;
      this.carPriceForm.get('modelName').setValue(this.model);
    } else {
      this.newLeaseCarForm = true;
      this.newUsedCarForm = false;
      this.carTypePick = false;
      this.carLeaseForm.get('modelName').setValue(this.model);
    }
    this.carTypeNewStatus = carType + ' parked';
    // this.carTypeNewStatus = carType;
  }

  tabOptions() {
    // setInterval(() => {
    //   this.ref.detectChanges();
    // }, 1000);
    // window.location.reload()
    // this.router.navigateByUrl('/admin', { skipLocationChange: true }).then(() => {
    //   this.router.navigate(['admin']);
    // });
    this.urls = [];
    this.showingLoad = false;
    this.resetAllForm();
    this.carTypePick = false;
    this.newUsedCarForm = false;
    this.soldUpdate = false;
    this.updateLeaseValues = false;
    this.updateNewUsedValues = false;
    this.firstOption = true;
    this.newLeaseCarForm = false;
    this.new = false;
    this.updateImages = false;
    this.fieldMissing = false;
    // this.product = {};
    this.filterInputVal = '';
    if (this.product) {
      for (const prop of Object.getOwnPropertyNames(this.product)) {
        delete this.product[prop];
      }
      // console.log(this.product);
    }

    this.mainImage = null;
    this.newMainImage = null;
    this.initial_specification = [];
    // this.initial_specification = this.initial_specification.filter(item => item !== data_item);
    // this.initial_specification = this.initial_specification.filter( ( el ) => toRemove.includes( el ) );
    // console.log(this.initial_specification);
    this.images = [];

    this.selectedFiles = [];
    this.finalFeatures = [];
    this.vinGotten = false;
    this.usedPicker = false;
    // Object.keys(this.product).forEach(key => this.product[key] = null);
    // console.log(this.product);
    this.vinForm.controls['vinNumber'].reset();
    this.finalSubmit = false;
    this.updateError = false;
    // this.submittedVin: boolean;
    // submittedSoldPrice: boolean;
    // submitted: boolean;
    // wrongPassword = false;
    // update = false;
    // new = false;
    // soldUpdate = false;
    // product: any;
    // wrongVin = false;
    // firstOption: boolean;

    // specification: any;
    // features: any;
    // featuresLength: any;
    // images: any = [];
    // initial_specification: any = [];
    // mainImage: any;
    // existingVin: boolean;
    // carTypePick: boolean;
    // newUsedCarForm: boolean;
    // submittedLeaseForm: boolean;
    // newLeaseCarForm: boolean;
    // latesturls: any[];
    // vinNumber: string;
    // carTypeNewStatus: string;
    // sellingPrice: string;
    // salePrice: string;
    // submittedCarPrice: boolean;
    // milage: any;
    // color: any;
    // description: any;
    // specInfo: any;
    // leaseDuration: any;
    // leaseRemain: any;
    // leaseCompany: any;
    // leaseFromDate: any;
    // leaseTotalMilage: any;
    // leaseRemainMilage: any;
    // leaseBuyBackAmount: any;
    // leaseVehicleTreInsurance: any;
    // leaseReturnInsurance: any;
    // leaseInitialTransferAmount: any;
    // alreadySold: boolean;
    // finalSubmit: boolean;
    // productDescription: any;
    // updateNewUsedValues: boolean;
    // updateLeaseValues: boolean;
    // updateImages: boolean;
    // submittedLeaseCarPrice: boolean;
    // preventSubmitNewUsed: boolean;
    // preventSubmitLease: boolean;
    // fieldMissing: boolean;
    this.alreadySold = false;
    this.year = "";
    this.model = "";
    this.brand = "";
    this.sku = "";
    this.showVin = "";
    this.carFax = "";
    this.masterSelected = false;
    this.carOptionsUpdate = false;
    this.carOptions = false;
    this.getCaroptions();








    // this.ref.markForCheck();
    // this.ngOnInit();
    // if (val === "update") {
    //   // this.update = true;
    //   // this.new = false;
    //   this.firstOption = true;
    // } else if (val == "new") {
    //   // this.update = false;
    //   // this.new = true;
    //   this.firstOption = true;
    // }
    // console.log(this.update, this.new);
  }

  onReset(form) {
    // this.submitted = false;
    this[form].reset();
    if (form === 'carPriceForm') {
      this.carPriceForm.get('modelName').setValue(this.model);
    }
    if (form === 'carLeaseForm') {
      this.carLeaseForm.get('modelName').setValue(this.model);
    }

  }

  onResetPreset(val) {
    this.showVin = this.product.showVin;
    if (val === 'new/used') {
      this.preventSubmitNewUsed = false;
      this.updatecarForm.get('sku').setValue(this.product.sku);
      this.updatecarForm.get('CarFax').setValue(this.product.carFax);
      this.updatecarForm.get('sellingPrice').setValue(this.product.priceIn);
      this.updatecarForm.get('salePrice').setValue(this.product.priceSale);
      this.updatecarForm.get('Milage').setValue(this.product.milage);
      this.updatecarForm.get('Color').setValue(this.product.color);
      this.updatecarForm.get('modelName').setValue(this.product.model);
      this.updatecarForm.get('Description').setValue(this.productDescription);
    } else {
      this.preventSubmitLease = false;
      this.carUpdateLeaseForm.get('sku').setValue(this.product.sku);
      this.carUpdateLeaseForm.get('CarFax').setValue(this.product.carFax);
      this.carUpdateLeaseForm.get('sellingPrice').setValue(this.product.priceIn);
      this.carUpdateLeaseForm.get('salePrice').setValue(this.product.priceSale);
      this.carUpdateLeaseForm.get('Milage').setValue(this.product.milage);
      this.carUpdateLeaseForm.get('Color').setValue(this.product.color);
      this.carUpdateLeaseForm.get('modelName').setValue(this.product.model);
      this.carUpdateLeaseForm.get('Description').setValue(this.productDescription);

      let tmpLeaseInfo = JSON.parse(this.product.leaseInfo);
      for (let key in tmpLeaseInfo) {
        if (key === 'Lease Duration') {
          this.carUpdateLeaseForm.get('leaseDuration').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease Remain') {
          this.carUpdateLeaseForm.get('leaseRemain').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease Company') {
          this.carUpdateLeaseForm.get('leaseCompany').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease From Date') {
          this.carUpdateLeaseForm.get('leaseFromDate').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease Total Milage') {
          this.carUpdateLeaseForm.get('leaseTotalMilage').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease Remain Milage') {
          this.carUpdateLeaseForm.get('leaseRemainMilage').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease Buy Back Amount') {
          this.carUpdateLeaseForm.get('leaseBuyBackAmount').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease Initial Transfer Amount') {
          this.carUpdateLeaseForm.get('leaseInitialTransferAmount').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease Return Insurance') {
          this.carUpdateLeaseForm.get('leaseReturnInsurance').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease Vehicle Tre Insurance') {
          this.carUpdateLeaseForm.get('leaseVehicleTreInsurance').setValue(tmpLeaseInfo[key]);
        }
      }
    }
  }
  imageConverter(status, images, vin) {
    // imageConverter() {

    let image;
    // let actualImage;
    // let images = images;
    let jsonImages = JSON.parse(images);
    let actualImage = jsonImages["images"][0]["name"];
    let result;
    if (status.toLowerCase() === 'sold') {
      result = Properties['Sold-Prefix-URL'] + "/" + Properties["DealerID"] + "-" + vin + "/" + actualImage;
    } else {
      result = Properties["ImageBoss-Url"] + Properties["ImageBoss-1400x1400"] + Properties["DealerID"] + "-" + vin + "/" + actualImage;
    }

    this.mainImage = result;
    this.newMainImage = result;
    // return (result);

  }
  getDescription() {
    let tmp = JSON.parse(this.product.featureInfo);
    this.productDescription = tmp.description;
    // return tmp.description;
    // console.log(this.productDescription);

    this.showVin = this.product.showVin;
    this.updatecarForm.get('modelName').setValue(this.product.model);
    this.updatecarForm.get('showVin').setValue(this.product.showVin);
    this.updatecarForm.get('sku').setValue(this.product.sku);
    this.updatecarForm.get('CarFax').setValue(this.product.carFax);
    this.updatecarForm.get('sellingPrice').setValue(this.product.priceIn);
    this.updatecarForm.get('salePrice').setValue(this.product.priceSale);
    this.updatecarForm.get('Milage').setValue(this.product.milage);
    this.updatecarForm.get('Color').setValue(this.product.color);
    this.updatecarForm.get('Description').setValue(this.productDescription);

    let tmpStatus = this.product.status.split(" ");

    if (tmpStatus[0] === 'lease') {
      this.carUpdateLeaseForm.get('modelName').setValue(this.product.model);
      this.carUpdateLeaseForm.get('showVin').setValue(this.product.showVin);
      this.carUpdateLeaseForm.get('sku').setValue(this.product.sku);
      this.carUpdateLeaseForm.get('CarFax').setValue(this.product.carFax);
      this.carUpdateLeaseForm.get('sellingPrice').setValue(this.product.priceIn);
      this.carUpdateLeaseForm.get('salePrice').setValue(this.product.priceSale);
      this.carUpdateLeaseForm.get('Milage').setValue(this.product.milage);
      this.carUpdateLeaseForm.get('Color').setValue(this.product.color);
      this.carUpdateLeaseForm.get('Description').setValue(this.productDescription);

      let tmpLeaseInfo = JSON.parse(this.product.leaseInfo);
      for (let key in tmpLeaseInfo) {
        if (key === 'Lease Duration') {
          this.carUpdateLeaseForm.get('leaseDuration').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease Remain') {
          this.carUpdateLeaseForm.get('leaseRemain').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease Company') {
          this.carUpdateLeaseForm.get('leaseCompany').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease From Date') {
          this.carUpdateLeaseForm.get('leaseFromDate').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease Total Milage') {
          this.carUpdateLeaseForm.get('leaseTotalMilage').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease Remain Milage') {
          this.carUpdateLeaseForm.get('leaseRemainMilage').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease Buy Back Amount') {
          this.carUpdateLeaseForm.get('leaseBuyBackAmount').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease Initial Transfer Amount') {
          this.carUpdateLeaseForm.get('leaseInitialTransferAmount').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease Return Insurance') {
          this.carUpdateLeaseForm.get('leaseReturnInsurance').setValue(tmpLeaseInfo[key]);
        }
        if (key === 'Lease Vehicle Tre Insurance') {
          this.carUpdateLeaseForm.get('leaseVehicleTreInsurance').setValue(tmpLeaseInfo[key]);
        }
      }
    }
  }
  getSpecification() {
    let tmp = JSON.parse(this.product.specInfo);
    let tmpSpecification = tmp.specification;

    // moving features to object if it exists else set to false
    if ('features' in tmpSpecification) {
      this.features = tmpSpecification.features;
      delete tmpSpecification['features'];
    } else {
      this.features = false;
    }
    // console.log(this.specification);
    for (var spec in tmpSpecification) {
      // console.log(spec);
      if (tmpSpecification[spec] == null) {
        // console.log(spec);
        delete tmpSpecification[spec];
      }
      if (spec.toLowerCase() == "vin" || spec.toLowerCase() == "year" || spec.toLowerCase() == "make" || spec.toLowerCase() == "model" || spec.toLowerCase() == "trim" || spec.toLowerCase() == "style" || spec.toLowerCase() == "drive" || spec.toLowerCase() == "type" || spec.toLowerCase() == "transmission") {

        this.initial_specification[spec] = tmpSpecification[spec];

        if (this.product.year == null || this.product.brand == null || this.product.model == null) {
          if (spec.toLowerCase() == "make") {
            this.product.brand = tmpSpecification[spec];
          } else if (spec.toLowerCase() == "model") {
            this.product.model = tmpSpecification[spec];
          } else if (spec.toLowerCase() == "year") {
            this.product.year = tmpSpecification[spec];
          }

        }
      }

    }
    this.specification = tmpSpecification;
    // console.log(this.specification, this.features);
  }

  generateArray(obj) {
    try {
      return Object.keys(obj).map((key) => { return { key: key, value: obj[key] } });
    } catch (e) {
      // console.log(e);
    }

  }
  getCleanedString(data) {
    data = data.replace(/_/g, ' ');
    return data;
  }
  upload() {
    let value = 0;
    return new Promise(resolve => {
      if (this.compressedImage && this.compressedImage.length >= 1) {

        value = this.compressedImage.length;

        // let value = this.compressedImage.length;
        let finalResult = [];
        let counter = 0;
        this.compressedImage.forEach((file, index) => {
          // console.log(file);
          let count = this.randomIntFromInterval(1, 10000);
          this.uploadService.uploadFile(file, Properties['DealerID'] + '-' + this.vinNumber.toUpperCase() + '/', this.vinNumber.toUpperCase() + '-img' + count, (result) => {
            // console.log(file.name);
            if (file.name === this.newMainImage) {
              finalResult.unshift(result.Location);
              this.newMainImage = result.Location;
            } else {
              finalResult.push(result.Location);
            }

            counter++;
            // console.log(counter);
            // console.log(counter, value);
            if (counter === value) {
              // console.log(finalResult);
              resolve(finalResult);
            }
          });
        });
      } else {
        resolve([]);
      }

    });
  }

  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  initialLeaseCalc() {
    if (this.product.salePrice == null) {
      var princ = this.product.priceIn;
    } else {
      var princ = this.product.salePrice;
    }

    var term = 72;
    var intr = 4.99 / 1200;
    let val = (princ * 1.13) * intr / (1 - (Math.pow(1 / (1 + intr), term)));
    return val.toFixed(2);
  }

  selectFile(event) {
    // this.selectedFiles = event.target.files;
    // if (event.target.files && event.target.files[0]) {
    //   var filesAmount = event.target.files.length;
    //   for (let i = 0; i < filesAmount; i++) {
    //     // let reader = new FileReader();
    //     // reader.onload = (e: any) => {
    //     this.urls.push(event.target.files[i].name);
    //     // this.urls.push(event.target.files[i].result);
    //     // }
    //   }
    // }
    // console.log(this.urls);
    // this.latesturls = [];
    // let files = event.target.files;
    // if (files) {
    //   for (let file of files) {
    //     let reader = new FileReader();
    //     reader.onload = (e: any) => {
    //       console.log(e.target.files);
    //       this.latesturls.push(e.target.result);
    //     }
    //     reader.readAsDataURL(file);
    //   }

    // }
    this.selectedFiles = [];
    this.urls = [];
    this.compressedImage = [];
    this.selectedFiles = Array.from(event.target.files);
    if (this.selectedFiles && this.selectedFiles[0]) {
      var filesAmount = this.selectedFiles.length;
      for (let i = 0; i < filesAmount; i++) {


        var reader = new FileReader();

        reader.onload = (event: any) => {
          // this.localUrl = this.selectedFiles[i].result;
          var fileName = this.selectedFiles[i]['name'];
          var type = this.selectedFiles[i]["type"];
          console.log(fileName);
          this.selectedFiles[i].result = this.ggg;
          this.compressFile(event.target.result, fileName, type);
          // console.log(tmp);
          // console.log(event.target.result);
          // console.log(this.selectedFiles[i].result);
          // console.log(this.selectedFiles[i]);
          // this.compressedImage.push(tmp);
          this.urls.push({ "image": URL.createObjectURL(this.selectedFiles[i]), "name": this.selectedFiles[i].name });
        }

        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
    // this.selectedFiles = [];
    // this.selectedFiles = Array.from(compressedImage);
    console.log(this.selectedFiles, this.urls, this.compressedImage);
  }

  removeImage(name) {
    console.log(name, this.selectedFiles);
    // this.selectedFiles.filter(function (url) {
    // let tmp = this.selectedFiles.splice(index, i);
    // let list = event.dataTransfer.files;
    // let i = 0;
    // Array.from(this.selectedFiles).forEach(file => {
    //   if (i != index) {
    //     list.items.add(file);
    //   }

    //   i++;
    // });

    // this.urls.splice(index, 1);
    // this.selectedFiles = list.files;
    // console.log(this.selectedFiles);
    // console.log(list.files);


    // var newFileList = Array.from(this.selectedFiles);
    // if(this.newMainImage === name){
    // this.newMainImage = this.mainImage
    // }
    this.compressedImage = this.compressedImage.filter(image => image.name !== name);
    this.urls = this.urls.filter(image => image.name !== name);
    // this.selectedFiles.splice(index, 1);
    console.log(this.compressedImage, this.urls);
    // this.selectedFiles = [];
    // this.latestFileList = newFileList;
    // this.urls.splice(index, 1);


    // if (this.selectedFiles && this.selectedFiles[0]) {
    //   var filesAmount = this.selectedFiles.length;
    //   for (let i = 0; i < filesAmount; i++) {
    //     var reader = new FileReader();

    //     reader.onload = (event: any) => {
    //       // console.log(event.target.result);
    //       // console.log(this.selectedFiles[i].result);
    //       // console.log(this.selectedFiles[i]);
    //       this.urls.push(event.target.result);
    //     }

    //     reader.readAsDataURL(this.selectedFiles[i]);
    //   }
    // for (let file in this.selectedFiles) {
    //   if(i != index){
    //     list.items.add(file);
    //   }

    //   i++;
    // }
    // let file = new File(["content"], "filename.jpg");


    // });
  }

  imagesSorter(status, vin) {
    let jsonImages = JSON.parse(this.product.images);
    // console.log(jsonImages);
    for (let image in jsonImages.images) {
      // console.log(image);
      if (status.toLowerCase() === 'sold') {
        this.images.push(Properties['Sold-Prefix-URL'] + "/" + Properties["DealerID"] + "-" + vin + "/" + jsonImages.images[image]["name"])
      } else {
        this.images.push(Properties["ImageBoss-Url"] + Properties["ImageBoss-1400x1400"] + Properties["DealerID"] + "-" + vin + "/" + jsonImages.images[image]["name"]);
      }

      // console.log(jsonImages.images[image]["name"]);
    }
    // console.log(this.images);

  }

  removeExistingImages(index) {
    this.images.splice(index, 1);
    // console.log(this.images);
  }

  async addCar() {
    // var self = this;

    if (this.vinNumber == null || this.product.year == null || this.product.model == null || this.carTypeNewStatus == null || this.product.brand == null || this.milage == null || this.sellingPrice == null || this.description == null ||
      this.color == null || this.specInfo == null) {
      this.fieldMissing = true;

      return;
    }
    if (this.newCarTypeForm.controls['carType'].value === 'lease' && (this.leaseDuration == null || this.leaseRemain == null || this.leaseCompany == null || this.leaseFromDate == null || this.leaseTotalMilage == null || this.leaseRemainMilage == null || this.leaseBuyBackAmount == null || this.leaseInitialTransferAmount == null || this.leaseReturnInsurance == null || this.leaseVehicleTreInsurance == null)) {
      this.fieldMissing = true;
      return;
    }
    this.showingLoad = true;
    this.new = false;
    // console.log("1");
    // await this.upload(result => {
    // console.log(result);
    // console.log("2");
    var carImageObj = { "images": [] };
    // console.log("3");
    // for (let i = 0; i < result.length; i++) {
    // console.log(result[i]);
    let result;
    result = await this.upload();

    for (let i = 0; i < result.length; i++) {
      var actualImage = result[i].split("/");
      // console.log("3 loop");
      carImageObj.images.push({ "name": actualImage[4] });
    }

    // console.log(result);
    // }
    // console.log("4");
    var description = { "description": this.description };


    let tmpYear = this.year;
    let tmpModel = this.model;
    let tmpBrand = this.brand;

    let val = JSON.parse(this.specInfo);
    if ("success" in val && val["success"] == true) {
      tmpYear = val["specification"]["year"];
      tmpModel = this.model;
      val["specification"]["model"] = this.model;
      tmpBrand = val["specification"]["make"];
      val["specification"]["features"] = this.finalFeatures;
    }
    // console.log(val);
    // console.log("5");
    this.car.specInfo = JSON.stringify(val);
    this.car.brand = tmpBrand;
    this.car.model = this.model;
    this.car.year = tmpYear;
    this.car.VehicleVin = this.vinNumber.toUpperCase();
    this.car.images = JSON.stringify(carImageObj);
    this.car.status = this.carTypeNewStatus;
    this.car.dateIn = this.currentDate();
    this.car.dateOut = null;
    this.car.milage = this.milage;
    this.car.priceIn = this.sellingPrice;
    this.car.luxuryTrim = null;
    this.car.convertable = null;
    this.car.featureInfo = JSON.stringify(description);
    this.car.priceOut = null;
    this.car.showVin = this.showVin;
    if (this.sku && this.sku !== null) {
      this.car.sku = this.sku;
    } else {
      this.car.sku = null;
    }
    if (this.carFax && this.carFax !== null) {
      this.car.carFax = this.carFax;
    } else {
      this.car.carFax = null;
    }
    // this.car.carFax = this.carFax;
    if (parseInt(this.salePrice) > 0) {
      this.car.priceSale = this.salePrice;
    } else {

      this.car.priceSale = null;
    }

    this.car.color = this.color;


    if (this.newCarTypeForm.controls['carType'].value === 'lease') {
      let tmpLease = {
        'Lease Duration': this.leaseDuration,
        'Lease Remain': this.leaseRemain,
        'Lease Company': this.leaseCompany,
        'Lease From Date': this.leaseFromDate,
        'Lease Total Milage': this.leaseTotalMilage,
        'Lease Remain Milage': this.leaseRemainMilage,
        'Lease Buy Back Amount': this.leaseBuyBackAmount,
        'Lease Initial Transfer Amount': this.leaseInitialTransferAmount,
        'Lease Return Insurance': this.leaseReturnInsurance,
        'Lease Vehicle Tre Insurance': this.leaseVehicleTreInsurance
      };

      this.car.leaseInfo = JSON.stringify(tmpLease);
    } else {
      this.car.leaseInfo = null;
    }
    // console.log("6");
    // console.log(JSON.stringify(carImageObj))
    // console.log(this.car);
    this.APIService.createCar(this.car).subscribe((data: {}) => {
      // console.log("7");
      this.tabOptions();
      this.finalSubmit = true;
      // this.router.navigate(['/admin/']);
    });
    // });

  }

  currentDate() {
    var currentDate = new Date();
    var day = currentDate.getDate();
    var month = currentDate.getMonth() + 1;
    var year = currentDate.getFullYear();
    return day + "/" + month + "/" + year;
  }

  finalURL() {
    return Properties["Host-Name"] + this.vinNumber;
  }

  resetAllForm() {
    this.vinForm.reset();
    this.soldPriceForm.reset();
    this.newCarTypeForm.reset();
    this.carPriceForm.reset();
    this.carLeaseForm.reset();
    this.updatecarForm.reset();
    this.carUpdateLeaseForm.reset();
    this.carImagesNew.reset();
  }

  CarOptionPick(val) {
    if (val === 'new') {
      this.firstOption = false;
      this.vinGotten = true;
      this.usedPicker = false;
      this.finalSubmit = false;
    } else if (val === 'update') {
      this.firstOption = false;
      this.showingLoad = true;
      return this.APIService.getAllCars().subscribe((data: {}) => {
        this.AllCars = data['body'];
        this.filterCars = [];
        for (var i = 0; i < this.AllCars.length; i++) {
          let tmp = {};
          tmp["year"] = this.AllCars[i]["year"];
          tmp["brand"] = this.AllCars[i]["brand"];
          tmp["model"] = this.AllCars[i]["model"];
          tmp["color"] = this.AllCars[i]["color"];
          tmp["status"] = this.AllCars[i]["status"];
          tmp["sku"] = this.AllCars[i]["sku"];

          // tmp["name"] = this.AllCars[i]["name"];

          let SpecInfo = JSON.parse(this.AllCars[i]["specInfo"]);
          tmp["vin"] = SpecInfo["vin"];
          tmp["transmission"] = SpecInfo["specification"]["transmission"];
          tmp["style"] = SpecInfo["specification"]["style"];
          this.filterCars.push(tmp);
        }
        // console.log(this.filterCars);
        // console.log(this.AllCars);
        this.showingLoad = false;
        this.usedPicker = true;
        this.finalSubmit = false;
        this.assignFilter();
        // console.log(this.Cars['body']);
      });
    }
  }
  assignFilter() {
    this.AllFilteredCars = Object.assign([], this.filterCars);
  }

  filterItem(val) {
    if (!val) {
      this.assignFilter();
    }
    let tmpVal = val.split(" ");
    // let search = '/^';
    let search = '';
    for (let val of tmpVal) {
      if (val !== null && val !== " ") {
        let tmpHold = '(?=.*\\b' + val.toLowerCase() + '\\b)';
        search = search + tmpHold;
      }
    }
    search = search + '';
    // search = search + '.*$/gi';

    this.AllFilteredCars = Object.assign([], this.filterCars).filter(
      function (item) {

        let rr = JSON.stringify(item);

        // for eachitem

        //let rd = rr.match(/(?=2013)(?=bmw)/gi);
        // let rd = rr.match(/^(?=.*\bhonda\b)(?=.*\b2011\b).*$/gi);/^(?=.*\b2013\b)(?=.*\bmercedes\b).*$/gi
        // let rd = rr.match('/(?=.*?\\b2013)(?=.*?\\bbmw).*/i');
        let t = new RegExp(search, 'gi');
        let tb = t.test(rr);
        return (tb);
        //let rd = rr.match('\\b2013\\b&\\bbmw\\b');
        // return rd;
        // for (const [key, value] of Object.entries(item)) {
        //   for (const check in tmpVal) {
        //     if (String(value).toLowerCase().indexOf(check.toLowerCase()) > -1) {
        //       return true;
        //     }
        //   }

        // }
      }
      // item => (key in item){

      // }

      // this.AllCars[item].toLowerCase().indexOf(val.toLowerCase()) > -1
    );
    // this.assignFilter();
  }

  populateVin(vin) {
    this.vinForm.get('vinNumber').setValue(vin);
    this.filterInputVal = '';
    this.usedPicker = false;
    this.vinGotten = true;
  }

  onKey(event) {
    const inputValue = event.target.value;
    if (Number.isInteger(parseInt(inputValue))) {
      this.newMainImage = this.images[inputValue];
    } else {
      this.newMainImage = inputValue;
    }
  }

  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  compressFile(image, fileName, type) {
    var orientation = -1;
    this.sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
    console.warn('Size in bytes is now:', this.sizeOfOriginalImage);

    this.imageCompress.compressFile(image, orientation, 50, 50).then(
      result => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
        console.warn('Size in bytes after compression:', this.sizeOFCompressedImage);
        // create file from byte
        const imageName = fileName;
        const mimeType = type;
        // call method that creates a blob from dataUri
        // this.urls.push({ "image": this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1], mimeType), "name": imageName });
        // this.urls.push({ "image": URL.createObjectURL(this.selectedFiles[i]), "name": this.selectedFiles[i].name });
        //imageFile created below is the new compressed file which can be send to API in form data
        // const imageFile = new File([result], imageName, { type: 'image/jpeg' });
        // tmp = new File([result], imageName, { type: 'image/jpeg' });
        const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1], mimeType);
        let value = new File([imageBlob], imageName, { type: mimeType })
        this.compressedImage.push(value);
        // return tmp;
      });
    // return tmp;
  }
  dataURItoBlob(dataURI, mimeType) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: mimeType });
    return blob;
  }


}
