import { Component, OnInit } from '@angular/core';
import { Properties } from ',,/../../src/environments/properties';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  panelOpenState = false;
  services: { "name": string; }[];
  fragment: string;
  constructor(private route: ActivatedRoute) { }
  

  ngOnInit() {
    this.services = Properties.Services;
    this.route.fragment.subscribe((fragment: string) => {
      this.fragment = fragment;
    })
  }
}
