import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { CarDetailsComponent } from './layout/car-details/car-details.component';
import { VehicleCardComponent } from './components/vehicle-card/vehicle-card.component';
import { HomeComponent } from './layout/home/home.component';
import { ModifyComponent } from './layout/modify/modify.component';
import { ProductListingComponent } from './layout/product-listing/product-listing.component';
import { ServicesComponent } from './layout/services/services.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxImageCompressService } from 'ngx-image-compress';

import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MatListModule, MatSidenavModule } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatExpansionModule } from '@angular/material/expansion';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ContactUsComponent } from './layout/contact-us/contact-us.component';
import { SanitizerUrlPipe } from 'src/app/sanitize-url.pipe';

@NgModule({
  declarations: [
    AppComponent,
    CarDetailsComponent,
    HomeComponent,
    ModifyComponent,
    VehicleCardComponent,
    FooterComponent,
    HeaderComponent,
    ProductListingComponent,
    ServicesComponent,
    ProductCardComponent,
    ContactUsComponent,
    SanitizerUrlPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatCardModule,
    MatButtonToggleModule,
    AngularFontAwesomeModule,
    NgbModule,
    MatListModule,
    MatIconModule,
    MatSidenavModule,
    CarouselModule,
    MatExpansionModule,
    Ng5SliderModule,
    MatDialogModule
  ],
  providers: [
    NgxImageCompressService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
