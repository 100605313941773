import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { Properties } from ',,/../../src/environments/properties';
@Injectable({
  providedIn: 'root'
})
export class UploadAWSService {

  constructor() { }

  async uploadFile(file, path, name, callback) {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: Properties["AWS-Access-Key"],
        secretAccessKey: Properties["AWS-Secret-Key"],
        region: Properties["AWS-Region"]
      }
    );
    const params = {
      Bucket: 'vehicles-images-kingstartauto',
      Key: path + name,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    await bucket.upload(params, function (err, data) {
      if (err) {
        return false;
      }
      callback(data);
    });
    //for upload progress   
    /*bucket.upload(params).on('httpUploadProgress', function (evt) {
          }).send(function (err, data) {
              if (err) {
                  return false;
              }
              return true;
          });*/
  }
  async emptyS3Directory(dir) {
    const bucket = 'vehicles-images-kingstartauto';
    const listParams = {
      Bucket: 'vehicles-images-kingstartauto',
      Prefix: dir
    };
    const s3 = new S3(
      {
        accessKeyId: Properties["AWS-Access-Key"],
        secretAccessKey: Properties["AWS-Secret-Key"],
        region: Properties["AWS-Region"]
      }
    );
    const listedObjects = await s3.listObjectsV2(listParams).promise();
    if (listedObjects.Contents.length === 0) return;

    const deleteParams = {
      Bucket: bucket,
      Delete: { Objects: [] }
    };

    listedObjects.Contents.forEach(({ Key }) => {
      deleteParams.Delete.Objects.push({ Key });
    });

    await s3.deleteObjects(deleteParams).promise();

    if (listedObjects.IsTruncated) await this.emptyS3Directory(dir);
  }

}
