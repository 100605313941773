import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './layout/home/home.component';
import { CarDetailsComponent } from './layout/car-details/car-details.component';
import { ModifyComponent } from './layout/modify/modify.component';
import { ProductListingComponent } from './layout/product-listing/product-listing.component';
import { ServicesComponent } from './layout/services/services.component';
import { ContactUsComponent } from './layout/contact-us/contact-us.component';
const routes: Routes = [
  { path: "product-listing", redirectTo: "/home", pathMatch: 'full' },
  { path: "home", component: HomeComponent },
  { path: "details/:id", component: CarDetailsComponent },
  { path: "admin", component: ModifyComponent },
  { path: "product-listing/:status", component: ProductListingComponent },
  { path: "product-listing/:status/:id", component: ProductListingComponent },
  { path: "services", component: ServicesComponent },
  { path: "contact-us", component: ContactUsComponent },
  { path: "details", redirectTo: "/home", pathMatch: 'full' },
  { path: "", redirectTo: "/home", pathMatch: 'full' },
  { path: "**", redirectTo: "/home", pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const RoutingComponent = [HomeComponent, CarDetailsComponent, ModifyComponent, ProductListingComponent, ServicesComponent, ContactUsComponent];