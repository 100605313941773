import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from '../../services/api.service';
import { Properties } from 'src/environments/properties';
import { Options, LabelType } from 'ng5-slider';

@Component({
  selector: 'app-product-listing',
  templateUrl: './product-listing.component.html',
  styleUrls: ['./product-listing.component.scss']
})
export class ProductListingComponent implements OnInit {
  brand: any;
  status: any;
  details: any;
  Cars: any = [];
  carsFiltered: any = [];
  filterUsed = false;
  minPrice: number = 0;
  maxPrice: number = 0;
  minYear: number = 1900;
  maxYear: number = 1900;

  optionsPrice: Options = {
    floor: 1000,
    ceil: 150000,
    // step: 1000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min price:</b> $' + value;
        case LabelType.High:
          return '<b>Max price:</b> $' + value;
        default:
          return '$' + value;
      }
    }
  };
  optionsYear: Options = {
    floor: 2000,
    ceil: new Date().getFullYear(),
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min Year:</b> ' + value;
        case LabelType.High:
          return '<b>Max Year:</b> ' + value;
        default:
          return 'Year: ' + value;
      }
    }
  };
  banner: string;
  carsLength: any;
  constructor(private route: ActivatedRoute, public APIService: APIService, private router: Router) { }

  ngOnInit() {
    this.route.url.subscribe(url => {
      this.status = url[1].path;
      if (this.status.toLowerCase() === 'inventory') {
        this.status = 'used';
      }
      if (url.length >= 3) {
        this.brand = url[2].path;
      }
      this.loadCars();
    });
    this.banner = Properties["ImageBoss-Url"] + Properties["ImageBoss-1200x400"] + "SuperCar-img2-Cut.png"
  }

  loadCars() {
    let tmp;
    return this.APIService.getAllCars().subscribe((data: {}) => {
      tmp = data['body'];
      if (this.brand) {
        this.brandSorter(tmp, this.brand, this.status);
      } else {
        if (this.status === 'used') {
          this.brand = 'INVENTORY';
        } else {
          this.brand = this.status;
        }

        this.statusSorter(tmp, this.status);
      }

    });

  }

  brandSorter(cars, brand, status) {
    this.Cars = cars.filter(function (car) { return car.brand.toLowerCase() === brand.toLowerCase() && car.status.toLowerCase() === status.toLowerCase() });
    if (this.Cars.length === 0) {
      this.router.navigate(['/home']);
    }
    this.carsLength = this.Cars.length;
    this.carsFiltered = this.Cars;
    this.minPrice = this.Cars[0].priceIn;
    this.maxPrice = this.Cars[0].priceIn;
    this.minYear = this.Cars[0].year;
    this.maxYear = this.Cars[0].year;
    this.valuesCalculator();
  }

  statusSorter(cars, status) {
    // this.Cars = cars.filter(function (car) { return car.status.toLowerCase() === status.toLowerCase() || car.status.toLowerCase() === 'sold' });
    // this.soldCars = cars.status.toLowerCase() === 'sold'
    // this.tmpCars = cars.status.toLowerCase() === status.toLowerCase()
    // this.Cars = this.soldCars + this.tmpCars

    let tmpCars = cars.filter(function (car) { return car.status.toLowerCase() === status.toLowerCase() });
    let soldCars = cars.filter(function (car) { return car.status.toLowerCase() === 'sold' });


    this.carsLength = tmpCars.length;

    this.Cars = tmpCars.concat(soldCars);
    if (this.Cars.length === 0) {
      this.router.navigate(['/home']);
    }
    this.carsFiltered = this.Cars;
    // this.minPrice = this.Cars[0].priceIn;
    // this.maxPrice = this.Cars[0].priceIn;
    // this.minYear = this.Cars[0].year;
    // this.maxYear = this.Cars[0].year;
    this.valuesCalculator();
  }

  valuesCalculator() {
    this.minPrice = parseInt(this.Cars[0].priceIn);
    this.maxPrice = parseInt(this.Cars[0].priceIn);
    this.minYear = this.Cars[0].year;
    this.maxYear = this.Cars[0].year;
    for (let car in this.Cars) {
      if (this.Cars[car].priceSale == null) {

        if (parseInt(this.Cars[car].priceIn) < this.minPrice) {
          this.minPrice = parseInt(this.Cars[car].priceIn);
        }

        if (parseInt(this.Cars[car].priceIn) > this.maxPrice) {
          this.maxPrice = parseInt(this.Cars[car].priceIn);
        }

      } else {

        if (this.Cars[car].priceSale < this.minPrice) {
          this.minPrice = parseInt(this.Cars[car].priceSale);
        }

        if (this.Cars[car].priceSale > this.maxPrice) {
          this.maxPrice = parseInt(this.Cars[car].priceSale);
        }

      }

      if (this.Cars[car].year < this.minYear) {
        this.minYear = parseInt(this.Cars[car].year);
      }

      if (this.Cars[car].year > this.maxYear) {
        this.maxYear = parseInt(this.Cars[car].year);
      }
    }
    this.optionsPrice.floor = this.minPrice;
    // this.updateLength();
  }

  filterCars(minPrice, maxPrice, minYear, maxYear) {
    this.filterUsed = true;
    this.Cars = this.carsFiltered;
    this.Cars = this.Cars.filter(function (car) {
      if (car.priceSale == null) {
        return parseInt(car.priceIn) >= minPrice && parseInt(car.priceIn) <= maxPrice && parseInt(car.year) >= minYear && parseInt(car.year) <= maxYear;
      } else {
        return parseInt(car.priceSale) >= minPrice && parseInt(car.priceSale) <= maxPrice && parseInt(car.year) >= minYear && parseInt(car.year) <= maxYear;
      }


    });
    this.updateLength();
  }

  updateLength() {
    let tmpCars = this.Cars.filter(function (car) { return car.status.toLowerCase() !== "sold" });


    this.carsLength = tmpCars.length;
  }
  resetCars() {
    this.filterUsed = false;
    this.Cars = this.carsFiltered;
    this.updateLength();
    this.valuesCalculator();
  }
}
