import { Component, OnInit, Input } from '@angular/core';
import { Properties } from ',,/../../src/environments/properties';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input() product: productInterface;
  constructor() { }

  ngOnInit() {
    this.missingValues();
  }

  missingValues() {

    let tmp = JSON.parse(this.product.specInfo);
    let tmpSpecification = tmp.specification;

    if (this.product.year == null || this.product.brand == null || this.product.model == null) {
      for (var spec in tmpSpecification) {
        if (spec.toLowerCase() == "make") {
          this.product.brand = tmpSpecification[spec];
        } else if (spec.toLowerCase() == "model") {
          this.product.model = tmpSpecification[spec];
        } else if (spec.toLowerCase() == "year") {
          this.product.year = tmpSpecification[spec];
        }
      }
    }

  }

  imageConverter(status, images, vin) {
    // imageConverter() {

    let image;
    // let actualImage;
    // let images = images;
    let jsonImages = JSON.parse(images);
    let result;
    try {
      let actualImage = jsonImages["images"][0]["name"];

      if (status.toLowerCase() === 'sold') {
        result = Properties['Sold-Prefix-URL'] + "/" + Properties["DealerID"] + "-" + vin + "/" + actualImage;
      } else {
        result = Properties["ImageBoss-Url"] + Properties["ImageBoss-1400"] + Properties["DealerID"] + "-" + vin + "/" + actualImage;
      }
    } catch (err) {
      result = Properties["ImageBoss-Url"] + Properties["ImageBoss-1400"] + "car-icon-default.png";
    }
    return (result);
  }


}
export interface productInterface {
  id: string;
  model: string;
  sports: string;
  baseInfo: object;
  DealerId: string;
  status: string;
  brand: string;
  dateIn: number;
  priceIn: number;
  luxuryTrim: string;
  convertable: string;
  featureInfo: object;
  dateOut: number;
  year: number;
  images: string;
  VehicleVin: string;
  priceOut: number;
  specInfo: string;
  name: string;
  priceSale: string;
  milage: string;
}


