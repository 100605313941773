import { Component, OnInit, ElementRef, Renderer2, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from '../../services/api.service';
import { Properties } from 'src/environments/properties';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { ImagePopupComponent } from 'src/app/components/image-popup/image-popup.component';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-car-details',
  templateUrl: './car-details.component.html',
  styleUrls: ['./car-details.component.scss']
})
export class CarDetailsComponent implements OnInit {
  id: any;
  details: any;
  product: any = [];
  description: any;
  specification: any;
  features: any;
  featuresLength: any;
  images: any = [];
  initial_specification: any = [];
  mainImage: any;
  leaseCalculatorForm: FormGroup;
  submitted = false;
  leaseCalculated = 0;
  carText: string;
  loanApplication: string;
  panelOpenState = true;
  carFaxImage: string;
  // leaseInfo: any;
  constructor(private route: ActivatedRoute, public APIService: APIService, private elRef: ElementRef, private dialog: MatDialog, private formBuilder: FormBuilder, private _renderer2: Renderer2, @Inject(DOCUMENT) private _document: any) { }

  ngOnInit() {
    // vsun comment out 05/29/2020 --> Car detail page description --> additional text; 
    this.carText = Properties["Car-Description-text"];
    //this.carText = "";
    this.loanApplication = Properties["Download-File"];
    this.carFaxImage = Properties["CarFax-Image"];
    this.id = this.route.snapshot.params.id;
    this.carDetails(this.id);
    let script = this._renderer2.createElement('script');
    // script.type = `application/ld+json`;
    script.text = `
        lightbox.option({
          'alwaysShowNavOnTouchDevices': true,
          'showImageNumberLabel': false,
          'wrapAround': true,
          'disableScrolling':true,
          'fitImagesInViewport' : true
        });
        `;
    this._renderer2.appendChild(this._document.body, script);

    this.leaseCalculatorForm = this.formBuilder.group({
      loanAmount: ['', Validators.required],
      loanLength: ['', Validators.required],
      interestRate: ['', Validators.required]
    });
  }

  downloadPdf() {
    const pdfUrl = this.loanApplication;
    const pdfName = 'Loan Application Form';
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  carDetails(id) {
    this.APIService.getCarByVin(id).subscribe((data: {}) => {
      this.product = data['body']['Item'];
      this.getDescription();
      this.getSpecification();
      this.carPrice();
      this.imageConverter(this.product.status, this.product.images, this.product.VehicleVin)
      this.imagesSorter(this.product.status, this.product.VehicleVin);
    });
  }
  getDescription() {
    let tmp = JSON.parse(this.product.featureInfo);
    this.description = tmp.description;

  }

  getLeaseInfo() {
    return JSON.parse(this.product.leaseInfo);
  }
  getSpecification() {
    let tmp = JSON.parse(this.product.specInfo);
    let tmpSpecification = tmp.specification;
    let topFeature = [];
    // moving features to object if it exists else set to false
    if ('features' in tmpSpecification) {

      for (let feature in tmpSpecification.features) {
        let tmp = tmpSpecification.features[feature].split("|");
        let tmpHighFeature = tmp[1];
        if (topFeature.indexOf(tmpHighFeature) === -1) {
          topFeature.push(tmpHighFeature);
        }
      }
      this.features = [];
      for (let topFeat in topFeature) {
        this.features[topFeature[topFeat]] = [];
      }
      for (let feature in tmpSpecification.features) {
        let tmp = tmpSpecification.features[feature].split("|");
        let tmpHighFeature = tmp[1];
        let tmpLowFeature = tmp[0];
        for (let [key, value] of Object.entries(this.features)) {
          if (tmpHighFeature == key) {
            // console.log(this.features[key].push(tmpLowFeature));
            this.features[key].push(tmpLowFeature);
            // topFeature.push(tmpHighFeature);
          }
        }

      }
      // console.log(this.features);
      // this.features = tmpSpecification.features;
      delete tmpSpecification['features'];

    } else {
      this.features = false;
    }
    // console.log(this.specification);
    for (var spec in tmpSpecification) {
      // console.log(spec);
      if (tmpSpecification[spec] == null) {
        // console.log(spec);
        delete tmpSpecification[spec];
      }
      if (spec.toLowerCase() == "make" || spec.toLowerCase() == "model" || spec.toLowerCase() == "year" || spec.toLowerCase() == "vin" || spec.toLowerCase() == "trim" || spec.toLowerCase() == "style" || spec.toLowerCase() == "drive" || spec.toLowerCase() == "type" || spec.toLowerCase() == "transmission") {
        if (!(tmpSpecification[spec]) && spec.toLowerCase() === "transmission") {
          // console.log("here", spec);
          this.initial_specification[spec] = "Automatic";
        } else if (spec.toLowerCase() === "vin") {
          // this.initial_specification["VIN NUMBER"] = tmpSpecification[spec];
        } else if (spec.toLowerCase() == "make") {
          this.initial_specification["Brand"] = tmpSpecification[spec];
        } else {
          this.initial_specification[spec] = tmpSpecification[spec];
        }

        if (this.product.year == null || this.product.brand == null || this.product.model == null) {
          if (spec.toLowerCase() == "make") {
            this.product.brand = tmpSpecification[spec];
          } else if (spec.toLowerCase() == "model") {
            this.product.model = tmpSpecification[spec];
          } else if (spec.toLowerCase() == "year") {
            this.product.year = tmpSpecification[spec];
          }

        }

      }


    }
    this.specification = tmpSpecification;
    // console.log(this.specification, this.features, this.initial_specification);
  }

  generateArray(obj) {
    try {
      return Object.keys(obj).map((key) => { return { key: key, value: obj[key] } });
    } catch (e) {
      // console.log(e);
    }

  }

  imageConverter(status, images, vin) {
    // imageConverter() {

    let image;
    // let actualImage;
    // let images = images;
    let jsonImages = JSON.parse(images);
    // console.log(jsonImages);
    if (jsonImages["images"].length > 0) {
      let actualImage = jsonImages["images"][0]["name"];
      let result;
      if (status.toLowerCase() === 'sold') {
        result = Properties['Sold-Prefix-URL'] + "/" + Properties["DealerID"] + "-" + vin + "/" + actualImage;
      } else {
        result = Properties["ImageBoss-Url"] + Properties["ImageBoss-1400x1400"] + Properties["DealerID"] + "-" + vin + "/" + actualImage;
      }

      this.mainImage = result;

      // return (result);
    }
  }
  imagesSorter(status, vin) {
    let jsonImages = JSON.parse(this.product.images);
    // console.log(jsonImages);
    for (let image in jsonImages.images) {
      // console.log(image);
      if (status.toLowerCase() === 'sold') {
        this.images.push(Properties['Sold-Prefix-URL'] + "/" + Properties["DealerID"] + "-" + vin + "/" + jsonImages.images[image]["name"])
      } else {
        this.images.push(Properties["ImageBoss-Url"] + Properties["ImageBoss-1400x1400"] + Properties["DealerID"] + "-" + vin + "/" + jsonImages.images[image]["name"]);
      }

      // console.log(jsonImages.images[image]["name"]);
    }
    // console.log(this.images);

  }

  cdnChange(image) {
    // alert(image);
    let tmp = image.replace(Properties["ImageBoss-1400x1400"], Properties["ImageBoss-1100"]);
    // console.log(tmp);
    return tmp;
  }
  updateMain(image) {
    this.mainImage = image;
    let elements = this.elRef.nativeElement.querySelectorAll(".owl-next");
    elements[0].click();

  }

  getCleanedString(data) {
    data = data.replace(/_/g, ' ');
    return data;
  }


  // openDialog() {
  //   this.dialog.open(ImagePopupComponent, {
  //     width: '90%',
  //     height: '80%',
  //     panelClass: 'my-dialog-container-class',
  //     data: {
  //       images: this.images
  //     }

  //   });
  // }

  get leaseCalculatorValues() {
    return this.leaseCalculatorForm.controls;
  }
  initialLeaseCalc() {
    if (this.product.priceSale == null) {
      var princ = this.product.priceIn;
    } else {
      var princ = this.product.priceSale;
    }

    var term = 72;
    var intr = 4.99 / 1200;
    // let val = (princ * 1.13) * intr / (1 - (Math.pow(1 / (1 + intr), term)));
    let val = (princ * 1.13) * intr / (1 - (Math.pow(1 / (1 + intr), term)));

    return val.toFixed(2);
  }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.leaseCalculatorForm.invalid) {
      return;
    }

    // var princ = this.product.priceIn;
    // if (this.product.priceSale == null) {
    //   var princ = this.product.priceIn;
    // } else {
    //   var princ = this.product.priceSale;
    // }
    var princ = this.leaseCalculatorForm.controls['loanAmount'].value;
    var term = this.leaseCalculatorForm.controls['loanLength'].value;
    var intr = this.leaseCalculatorForm.controls['interestRate'].value / 1200;
    this.leaseCalculated = Math.round((princ * 1.13) * intr / (1 - (Math.pow(1 / (1 + intr), term))));

    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.leaseCalculatorForm.value, null, 4));
  }

  onReset() {
    this.submitted = false;
    // this.leaseCalculatorForm.reset();
    this.carPrice();
    this.leaseCalculatorForm.controls['loanLength'].reset();
    this.leaseCalculatorForm.controls['interestRate'].reset();
    this.leaseCalculated = 0;
  }

  carPrice() {
    if (this.product.priceSale == null) {
      var princ = parseInt(this.product.priceIn) * 1.13;
      // console.log("here", this.product.priceSale);
    } else {
      var princ = parseInt(this.product.priceSale) * 1.13;
    }
    // return Math.round(princ);
    this.leaseCalculatorForm.get('loanAmount').setValue(Math.round(princ));
  }

  gallonToLiters(val) {
    let num = val.split(" ");
    let numTmp = parseInt(num[0]);
    let final = 235.21 / numTmp;
    return final.toFixed(2) + " Liters per 100KM";
  }
  customOptions: OwlOptions = {
    items: 10,
    autoHeight: false,
    autoWidth: true,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    lazyLoad: false,
    smartSpeed: 900,
    // autoplay: true,
    // @ts-ignore
    navText: ["<i class='fa' style='margin:0px !important;'></i>", "<i class='fa'></i>"],
    responsive: {
      0: {
        items: 1
      },
      480: {
        items: 2
      },
      768: {
        items: 3
      },
      992: {
        items: 4
      }
    }
  }



}

